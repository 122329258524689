import { Grid, TextField, Box, InputAdornment, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useAppDispatch } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import URLCopy from "assets/url-copy.svg";

interface IUrlFieldProps {
  field: any;
  control: any;
  errors: any;
}

const UrlField = ({ field, control, errors }: IUrlFieldProps) => {
  const dispatch = useAppDispatch();

  const copyContent = async (value) => {
    try {
      if (value) {
        await navigator.clipboard.writeText(value);
        dispatch(snackbarRequest({ message: "copied successfully" }));
      } else {
        dispatch(
          snackbarRequest({ message: "no data to copy", type: "warning" })
        );
      }
    } catch (err) {
      dispatch(snackbarRequest({ message: "failed to copy", type: "error" }));
    }
  };

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <TextField
              onChange={onChange}
              value={value}
              label={field.label}
              required={field.required}
              type="url"
              placeholder="Enter Url.."
              variant="outlined"
              error={errors && errors[field.id]?.message}
              sx={{ width: "100%" }}
              size="small"
              InputProps={Object.assign(
                { readOnly: field.readOnly ? field.readOnly : false },
                field.icon && {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ cursor: "pointer", height: "40px" }}
                      onClick={() => copyContent(value)}
                    >
                      <div
                        style={{
                          borderLeft: "1px solid #ced4da",
                          height: "40px",
                        }}
                      >
                        <Tooltip title="copy">
                          <img
                            src={URLCopy}
                            style={{ marginTop: "8px", marginLeft: "10px" }}
                          />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }
              )}
            />
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
            {errors && errors[field.id]?.message && (
              <FieldError field={field} errors={errors} />
            )}
          </>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
    </Grid>
  );
};

export default UrlField;
