import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
    concat
  } from "@apollo/client";
  
   const authMiddleware = new ApolloLink(
     (operation: any, forward: any) => {
       const headers = {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        };
       operation.setContext(() => ({
         headers: {
           ...headers,
         },
       }));
  
       return forward(operation);
     }
   );
  
  const httpLink = new HttpLink({
    uri: "https://stage-nlearn.gcf.education/nkids/games/gq/v1/ha",
  });
  
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
  
  export default client;