import React from "react";
import { Stack } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "@mui/material/Tooltip";
import TestUrl from "assets/build-test-url.svg";

type ButtonProps = {
  handleStore?: any;
  handleEdit?: () => void;
  handleDeliver?: () => void;
  handlePreview?: () => void;
  handleAnswer?: any;
  test: any;
  handleCopy?: () => void;
  addUrlNavigation?: () => void;
};

export const MultiAcitonButtons = (props: ButtonProps) => {
  const {
    test,
    handleStore,
    handleEdit,
    handleDeliver,
    handlePreview,
    handleAnswer,
    addUrlNavigation,
  } = props;
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      <Tooltip title="Store Test">
        <SaveOutlinedIcon onClick={() => handleStore(test)} />
      </Tooltip>
      <Tooltip title="Edit Test">
        <EditIcon onClick={handleEdit} />
      </Tooltip>
      <Tooltip title="Deliver Test">
        <AssignmentTurnedInOutlinedIcon onClick={handleDeliver} />
      </Tooltip>
      <Tooltip title="Preview PDF">
        <PictureAsPdfOutlinedIcon onClick={handlePreview} />
      </Tooltip>
      <Tooltip title="Answer Key">
        <ArticleOutlinedIcon onClick={() => handleAnswer(test)} />
      </Tooltip>
      <Tooltip title="Instruction Url">
        <img
          src={TestUrl}
          style={{ height: "24px", width: "24px" }}
          onClick={addUrlNavigation}
        />
      </Tooltip>
    </Stack>
  );
};
