import styled from "@emotion/styled";
import { Box, Tooltip } from "@mui/material";

interface IProps {
  width: string;
  item: string;
  widthReducer?: string;
}

const Wrapper: any = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Ellipsis(props: IProps) {
  const { width, item, widthReducer } = props;
  const WR = widthReducer || "0px";
  return (
    <>
      <Tooltip title={item}>
        <Wrapper style={{ width: `calc(${width} - ${WR})` }}>{item}</Wrapper>
      </Tooltip>
    </>
  );
}

export default Ellipsis;
