import { useRef, useState } from "react";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import awsConfig from "aws-config";
import Amplify, { Storage } from "aws-amplify";
import { withStyles } from "@mui/styles";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import "./styles.css";

type UploadProps = {
  onChange: any;
  fields: any;
  getValues: any;
  classes: any;
  value: any;
  className: string;
  watch?: any;
  errors: any;
  indexNumber?: any;
};

const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: "red",
    color: "white",
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "green",
    color: "white",
  },
};

Amplify.configure({
  Auth: {
    identityPoolId: awsConfig.identity_pool_id,
    region: awsConfig.aws_cognito_region,
  },
});

Storage.configure({
  AWSS3: {
    bucket: awsConfig.aws_user_files_s3_bucket,
    region: awsConfig.aws_user_files_s3_bucket_region, //OPTIONAL -  Amazon service region
  },
});

const Upload = ({
  onChange,
  fields,
  getValues,
  classes,
  className,
  value,
  watch,
  errors,
  indexNumber,
}: UploadProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<any>([]);
  const [imagesNamesList, setImagesNamesList] = useState<string[]>([]);

  const [dragging, setDragging] = useState(false);

  const getPath = () => {
    const values = getValues();
    let path = "others";

    if (fields.feature === "assignments") {
      path = `assignments/${values.class_id}/course/${values.subject_id}/chapter`;
    } else if (fields.feature === "schedule") {
      path = `schedule/${values.class_id}/${values.course_id}/`;
    } else if (fields.feature === "term-exams") {
      path = `descriptive-exam/${values.class_id}`;
    } else if (fields.feature === "learn") {
      path = `learn/resources/${values.class_id}/${
        values.course_id || "course"
      }/${values.subject_id || "subject"}/${values.topic_id || "chapter"}`;
    } else if (fields.feature === "build-test") {
      path = `build-test`;
    } else if (fields.feature === "announcements") {
      path = `announcements`;
    } else if (fields.feature === "fun-sheet") {
      path = `fun-sheet`;
    } else if (fields.feature === "magazine") {
      path = `magazine`;
    }
    return path;
  };

  const handleCapture = async (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    const { dataTransfer, target } = event;
    let file = dataTransfer ? dataTransfer.files : target.files;
    let nxtFileNames: any =
      file &&
      Object.values(file).map((item: any, idx) =>
        item.name.split(" ").join("_")
      );

    let extensionType =
      file && file[0].name.substring(file[0].name.lastIndexOf(".") + 1);
    let fileSize = 50;
    if (fields.size) fileSize = fields.size;
    const sizes = Object.values(file).map((item: any) => item.size);
    const totalSize = sizes.reduce((pre, curr) => pre + curr, 0);
    let isFileSizeOverflow =
      file && !(Math.ceil(totalSize / 1000000) <= fileSize);
    if (
      file &&
      !isFileSizeOverflow &&
      fields.fileType.includes(`.${extensionType}`)
    ) {
      setShowLoading(true);
      try {
        let folderName = getPath();

        // const result = await Storage.put(
        //   `${folderName}/${nxtFileName}`,
        //   file,
        //   {}
        // );

        let finalResult: any = [];
        for (let i = 0; i < nxtFileNames.length; i++) {
          let value: any = await Storage.put(
            `${folderName}/${nxtFileNames[i]}`,
            file[i],
            {}
          );
          // console.log("check result value", value)
          finalResult.push(value);
        }
        if (finalResult[0]?.key) {
          setShowLoading(false);
          dispatch(snackbarRequest({ message: "Successfully Uploaded" }));

          setImagesNamesList([...imagesNamesList, ...nxtFileNames]);

          let fileNames: any = [];
          for (let i = 0; i < nxtFileNames.length; i++) {
            let value: any = `https://${awsConfig.aws_user_files_s3_bucket}.s3.${awsConfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/${folderName}/${nxtFileNames[i]}`;
            fileNames.push(value);
          }

          if (fileNames) {
            const values = value && value.length && value.map((item:any) => item.image_url)
            // setImages([...values, ...fileNames]);
            setImages([ ...fileNames]);
          }
        }
        event.target.value = null;
      } catch (err) {
        setShowLoading(false);
        console.log(`PUT in error${err}`);
      }
    } else {
      setShowLoading(false);
      let message = "Please select valid file type";
      if (isFileSizeOverflow)
        message = `File size is more than ${fields.size}, Please select valid file`;
      dispatch(snackbarRequest({ message: message, type: "error" }));
    }
  };

  useEffect(() => {
    // const mainData:any = Array.from(new Set(images))
    const mainData = images
    const val = mainData && mainData.length > 0 && mainData.map((item, index) => {
      const nArr = item && item.length > 0 && item?.split(/\/|\.|-/);
      return {
        image_url: item,
        image_name: `${nArr[nArr.length - 3]}_${nArr[nArr.length - 2]}`,
        sort_order: Number(nArr[nArr.length - 2]),
        is_hilight: false,
      };
    });
    onChange(val);
  }, [images]);

  const handleValidate = (event) => {
    handleCapture(event);
  };

  //=================================================

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleCapture(e);
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    let drop = dropRef.current;

    drop.addEventListener("dragenter", handleDragIn);
    drop.addEventListener("dragleave", handleDragOut);
    drop.addEventListener("dragover", handleDrag);
    drop.addEventListener("drop", handleDrop);

    return () => {
      drop.removeEventListener("dragenter", handleDragIn);
      drop.removeEventListener("dragleave", handleDragOut);
      drop.removeEventListener("dragover", handleDrag);
      drop.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <>
      {showLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={showLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div ref={dropRef}>
        <div className={`drop-area ${dragging && "drag-area"}`}>
          <input
            type="file"
            onChange={handleCapture}
            multiple
            name="file"
            id="file"
            ref={fileInputRef}
            accept={fields.acceptTypes}
          />
          <div className="text-area">
             <span>+</span>
             <span>Add/Drag</span>
             <span>images</span>
             <span>here</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Upload);
