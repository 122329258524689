export let metaData = {
  state_list: [
    {
      state_id: "stam",
      state_name: "Tamilnadu",
      city_list: [
        {
          city_id: "cche",
          city_name: "Chennai",
        },
      ],
    },
    {
      state_id: "stel",
      state_name: "Telangana",
      city_list: [
        {
          city_id: "chyd",
          city_name: "Hyderabad",
        },
        {
          city_id: "cwar",
          city_name: "Warrangal",
        },
      ],
    },
  ],
}

type cityProps = {
  city_id: string
  city_name: string
}
//to generatelabel and value
export const genearteLabelAndValue = (data: []) => {
  return (
    data &&
    data.map((city: cityProps) => ({
      label: city.city_id,
      value: city.city_name,
    }))
  )
}

type ErrorProps = {
  [key: string]: {
    [key: string]: string
  }
}

//to check all fields are not empty and errors are empty
export const isFieldsValidate = (
  errors: any,
  fields: { id: string }[],
  getValues: any
) => {
  let emptyFields: any = [];
  let allErrorEmpty: any = [];
  for (const { id } of fields) {
    if (errors && errors[id] && errors[id] && errors[id]["message"]) {
      allErrorEmpty.push(errors[id] && errors[id]["message"]);
    } else {
      allErrorEmpty.push("");
    }
    const res = getValues(id);
    emptyFields.push(getValues(id));
  }
  const isEveryFieldNotEmpty = emptyFields.every((field: any) => {
    if (Array.isArray(field)) return field.length > 0;
    return field !== "" && field !== undefined
  });
  const isEveryErrorMessageEmpty = allErrorEmpty.every((field) => field === "");

  return (
    isEveryFieldNotEmpty &&
    isEveryErrorMessageEmpty &&
    Object.keys(errors).length === 0
  );
};
