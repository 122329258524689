import { FormControl, Grid, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import FieldError from "shared/organisms/dynamicform/FieldError"
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material"

interface IRadioFieldProps {
  field: any
  control: any
  errors: any
}

const RadioField = ({ field, control, errors }: IRadioFieldProps) => {
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <FormControl component="fieldset">
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={value}
              onChange={onChange}
            >
              {field.radioFields.map(
                (radiofield: { label: string; value: string }) => (
                  <FormControlLabel
                    value={radiofield.value}
                    control={<Radio />}
                    label={radiofield.label}
                    className="radio-label"
                  />
                )
              )}
            </RadioGroup>
          </FormControl>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
    </Grid>
  )
}

export default RadioField
