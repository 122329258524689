import TableCell from "@mui/material/TableCell";
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
  paddings:{
    "& .css-12qr4rk-MuiTableCell-root":{
      padding:0,
      color:'red'
    }
  }
})

function TableTitle({ data }: any) {
  const styles = useStyles()
  return (
    <>
      {data.map((item: any) => (
        <TableCell key={item.title} className={styles.paddings}>{item.title}</TableCell>
      ))}
    </>
  );
}

export default TableTitle;
