import React from "react";
import Box from "@mui/material/Box";
import { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";

export const CustomPopper = ({
  children,
  onAgree,
  confirmationText = "Are you sure want to delete",
  text = "delete",
  sx = {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [open, setOpen] = React.useState(false);
  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  return (
    <>
      <Box sx={{ ...sx, backgroundColor: "#fff" }}>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#fff",
                border: "1px solid #f5f6f6",
              },
            },
            arrow: { sx: { color: "#ffd992" } },
          }}
          placement={"left"}
          open={open}
          onOpen={() => {}}
          onClose={() => {}}
          title={
            <Paper sx={{ p: 2 }} elevation={0}>
              <Typography sx={{ p: 2 }}>{confirmationText}</Typography>
              <Box sx={{ textAlign: "right" }}>
                <Button onClick={() => setOpen(false)} color="success">
                  Cancel
                </Button>
                <Button onClick={() => onAgree(setOpen(false))} color="error">
                  {text}
                </Button>
              </Box>
            </Paper>
          }
        >
          <Box></Box>
        </Tooltip>
        <Box component="span" onClick={handleClick("left-start")}>
          {children}
        </Box>
      </Box>
    </>
  );
};
