import {useState} from 'react'
import { Controller } from 'react-hook-form'
import FieldError from 'shared/organisms/dynamicform/FieldError'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles';

interface IMultiTextProps {
    field: any
    control: any
    errors: any
    getValues:any
    watch:any
}

const useStyles = makeStyles({
    inputField: {
        paddingBottom:'8px',
        paddingTop:'8px',
        paddingLeft:'8px',
        width:'40px',
        '& .MuiInput-root:before':{
            borderBottom:'none'
        },
        '& .MuiInput-root:after':{
            borderBottom:'none'
        },
        '& .MuiInput-root:hover:before':{
            borderBottom:'none'
        }
    },
    boxField: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #b0b0b0",
        borderRadius: '3px',
        padding:2,
    },
    typography:{
        marginRight:"7px",
        marginLeft:"5px",
    }
});

const MultiTextComponent = ({ field, control, errors, getValues, watch }: IMultiTextProps) => {
    const [data, setData]= useState<any>({
        questions:'',
        marks:''
    })
    const classes = useStyles()
    const changeHandler=(e,onChange)=>{
        const {name, value}= e.target
        const dataValue={...data,[name]:value}
        setData({...dataValue})
        onChange(dataValue)
    }
    return (
        <Grid item {...field.breakpoints}>
            <Controller
                name={field.id}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <>
                        <Stack direction='row' alignItems='center'>
                            <Box
                                component="form"
                                className={classes.boxField}
                                sx={{height:'40px'}}
                            >
                                <TextField
                                    name="questions"
                                    variant="standard"
                                    onChange={(e)=>changeHandler(e,onChange)}
                                    value={data.questions}
                                    className={classes.inputField}
                                    size='medium'
                                />
                                <Typography variant="body1" className={classes.typography}>X</Typography>
                                <TextField
                                    name="marks"
                                    variant="standard"
                                    onChange={(e)=>changeHandler(e,onChange)}
                                    value={data.marks}
                                    className={classes.inputField}
                                    size='medium'
                                />
                                <Typography variant="body1" className={classes.typography}>M</Typography>
                            </Box>
                            {
                                data.questions && data.marks && <Typography variant='h5' sx={{fontWeight:'bold'}}>= {`${data.questions * data.marks}`} M</Typography>
                            }
                        </Stack>
                        {errors && errors[field.id]?.message && (
                            <FieldError field={field} errors={errors} />
                        )}
                    </>
                )}
            />
        </Grid>
    )
}

export default MultiTextComponent
