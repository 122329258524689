import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ChildrenEntity } from "shared/organisms/sidebar/interfaces";
import { useStyles } from "./styles";

const StyledNavLink = styled(NavLink)<{ canAccess?: boolean }>(({ theme ,canAccess }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 8px 10px 12px",
  margin: "4px 8px",
  borderRadius: "4px",
  textDecoration: "none",
  color: theme.palette.text.primary,
  opacity: canAccess ? 0.8 : 0.4,
  cursor: canAccess ? "pointer" : "not-allowed",
  pointerEvents: canAccess ? "auto" : "none",
  "&.active": {
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    fontWeight: 500,
  },
  "&:hover": {
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    fontWeight: 500,
  },
}));

export default function NavItem(props: { navItemData: ChildrenEntity ,canAccess?:boolean}) {
  const styles = useStyles();
  const { navItemData ,canAccess} = props;

  return (
    <StyledNavLink to={navItemData.link || ""} canAccess={canAccess} >
      {navItemData.imageType == "svg" ? (
        <ListItemIcon sx={{ minWidth: "24px", minHeight: "24px" }}>
          <img style={{ color: "white" }} src={navItemData.icon} alt="/" />
        </ListItemIcon>
      ) : (
        <ListItemIcon sx={{ minWidth: "24px", minHeight: "24px" }}>
          {navItemData.icon ? <navItemData.icon sx={{color:'white'}}/> : null}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ fontSize: "13px", paddingLeft: "20px", color: "white" }}
        primary={navItemData.name}
        className={styles.listItems}
      />
    </StyledNavLink>
  );
}
