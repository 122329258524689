import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  UpdateSingleAppDetails,
  forceUpdateRequest,
} from "pages/configue/slices/force-update-slice";
import { useEffect, useState } from "react";
import SectionTable from "shared/organisms/tables/table";
import { table } from "./helper";
import { useHistory } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import Loader from "pages/build-test/loader";

export const ForceUpdate = () => {
  const dispatch = useAppDispatch();
  const [appDetails, setAppDetails] = useState<any>([]);
  const { data, loading } = useAppSelector((state) => state.forceUpdateDetails);
  const history = useHistory();
  //Loader
  const handleEdit = (version_id: any) => {
    const getSelectedApp = data.filter((item) => {
      if (item.version_id == version_id) {
        return item;
      }
    });
    if (getSelectedApp.length > 0) {
      dispatch(UpdateSingleAppDetails(getSelectedApp[0]));
    }
    history.push(`/configue/force_update/edit/${version_id}`);
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singlePlateform = { ...item };
      singlePlateform["is_force_update"] =
        item.is_force_update === true ? "true" : "false";
     // singlePlateform["created_at"] = formatDate(item.created_at);
      //singlePlateform["updated_at"] = formatDate(item.updated_at);
      return singlePlateform;
    });
    setAppDetails(tableData);
  };

  //   "version_id": 6,
  //   "platform": "web",
  //   "version_number": "3",
  //   "is_force_update": true,
  //   "created_at": "2020-08-15T14:40:48.593Z",
  //   "updated_at": "2020-08-15T14:40:48.593Z",
  //   "app": "nlearn",
  //   "version_name": null

  useEffect(() => {
    dispatch(forceUpdateRequest());
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // covert data intotable formate
      convertDataToTableData();
    }
  }, [data]);

  return (
    <div>
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        App Details
      </Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        <div>
          {loading && <Loader />}
          {!loading && appDetails.length > 0 && (
            <SectionTable
              data={appDetails}
              table={table}
              id="version_id"
              name="version_id"
              handleEdit={handleEdit}
              //   handleCopy={handleCopy}
              rowsPerPageCount={50}
              // pagination="true"
              style="false"
              totlaRecords={0}
              // hasPageApiCall={false}
              paginationSegment="false"
              viewSecondaryData="false"
            />
          )}
        </div>
      </Paper>
    </div>
  );
};
