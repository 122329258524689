import request from "utils/axios/service";
import { getItem, setItem } from "utils/helpers";

export const convertToLabelValueFormat = (field: any, data) => {
  if (!data || data.length === 0) return [];
  return data.map((item: any) => ({
    label: item[field.keyValue.label],
    value: item[field.keyValue.value],
    url: item.template_file_url,
  }));
};

export const getDataFromApi = async (field, values,cb) => {
  const res: any = await request.get(field.endpoint, values);
  if (res && res.status === 200 && res.data.status === "success") {
    const dummyRes=await cb(res.data.data)
    return convertToLabelValueFormat(field, res.data.data);
  }
};
