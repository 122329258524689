import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, put, call } from "redux-saga/effects";
import configueService from "./service";
import {
  forceUpdateRequest,
  forceUpdateSuccess,
} from "./slices/force-update-slice";
import {
  StudentCourseLookupRequest,
  StudentCourseLookupSuccess,
  emptyCourseData,
} from "./slices/student-course-slice";
import {
  emptyConfigData,
  featureConfigRequest,
  featureConfigSuccess,
} from "./slices/feature-config-slice";
import {
  StudentProgramLookupRequest,
  StudentProgramLookupSuccess,
  emptyProgramData,
} from "./slices/student-program-slice";

function* getAppVersionsList() {
  try {
    const res = yield call(configueService.getAppVersionListApi);
    if (res.status === 200 && res.data.status === "success") {
      yield put(forceUpdateSuccess(res.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getCourseLookup(action: PayloadAction<any>) {
  try {
    const res = yield call(
      configueService.getCourseLookupListApi,
      action?.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(StudentCourseLookupSuccess(res.data.data));
    } else if (res.data.status === "error") {
      yield put(emptyCourseData());
    }
  } catch (e) {
    console.log("error", e);
  }
}

function* getProgramLookup(action: PayloadAction<any>) {
  try {
    const res = yield call(
      configueService.getProgramLookupListApi,
      action?.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(StudentProgramLookupSuccess(res.data.data));
    } else if (res.data.status === "error") {
      yield put(emptyProgramData());
    }
  } catch (e) {}
}

function* getConfigList(action: PayloadAction<any>) {
  try {
    const res = yield call(configueService.getConfigListApi, action?.payload);
    if (res.status === 200 && res.data.status === "success") {
      yield put(featureConfigSuccess(res.data.data));
    } else if (res.data.status === "error") {
      yield put(emptyConfigData());
    }
  } catch (e) {}
}

export default function* configueSaga() {
  yield takeLatest(forceUpdateRequest.type, getAppVersionsList);
  yield takeLatest(StudentCourseLookupRequest.type, getCourseLookup);
  yield takeLatest(StudentProgramLookupRequest.type, getProgramLookup);
  yield takeLatest(featureConfigRequest.type, getConfigList);
}
