import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";

interface IEmailFieldProps {
  field: any;
  control: any;
  errors: any;
}

const EmailField = ({ field, control, errors }: IEmailFieldProps) => {
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <TextField
              onChange={onChange}
              value={value}
              label={field.label}
              type="email"
              required={field.required}
              placeholder="Enter Email.."
              className={`${field.className}`}
              error={errors && errors[field.id]?.message}
              variant="outlined"
              {...field.extraUiProps}
            />
            {errors && errors[field.id]?.message && (
              <FieldError field={field} errors={errors} />
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default EmailField;
