import useReactHookForm from "hooks/useReactHookForm";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useRouteMatch, useHistory } from "react-router-dom";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {
  getBatchListData,
  getCollegeListData,
} from "utils/metadata-split/metaDataApiFetch";
import {
  createConfigInitialValues,
  createConfigMandatoryFields,
  createConfigProperties,
  createConfigValidationSchema,
} from "./helper";
import {
  getBatchFromClassId,
  getBatchFromClassIdForMs,
  getSubBatchFromBatchId,
  getSubBatchFromBatchIdForUp,
} from "utils/metadata-split/depedentApiFetch";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { areArraysEqual, setReactFormValues } from "pages/configue/constants";
import { UpdatefeatureConfigDetails } from "pages/configue/slices/feature-config-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";

const initialJsonData = {};

export const UpdateConfig = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createConfigInitialValues,
    createConfigValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const match: any = useRouteMatch();
  const history = useHistory();
  const [fetchAll, setFetchAll] = useState(true);
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const [jsonData, setJsonData] = useState(initialJsonData);
  const { data, updateConfigDetails } = useAppSelector(
    (state) => state.featureConfigApiSlice
  );

  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const handleJsonChange = (e) => {
    if (e.jsObject) {
      setJsonData(e.jsObject);
    }
  };

  const handleClick = async () => {
    if (match?.url?.includes("duplicate")) {
      const reqBody = {
        id: "id",
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        sub_batch_list: watch()?.sub_batch_list,
        college_list: watch()?.college_list,
        platform_list: watch()?.platform_list,
        is_global_config: watch()?.is_global_config == "true" ? true : false,
        is_early_bird_config:
          watch()?.is_early_bird_config == "true" ? true : false,
        config_json: jsonData,
      };
      const res: any = await configueService.createConfigApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "created successfully" }));
        history.replace(`/configue/feature_configue`);
      }else{
        dispatch(snackbarRequest({message:res.data.message,type:"error"}));
      }
    } else {
      const reqBody = {
        id: match?.params?.config_id,
        academic_year_list: watch()?.academic_year_list,
        registration_status_list: watch()?.reservation_status_list,
        class_list: watch()?.class_list,
        batch_list: watch()?.batch_list,
        sub_batch_list: watch()?.sub_batch_list,
        college_list: watch()?.college_list,
        platform_list: watch()?.platform_list,
        is_global_config: watch()?.is_global_config == "true" ? true : false,
        is_early_bird_config:
          watch()?.is_early_bird_config == "true" ? true : false,
        config_json: jsonData,
      };
      const res: any = await configueService.updateConfigApi(reqBody);
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(snackbarRequest({ message: "updated successfully" }));
        history.replace(`/configue/feature_configue`);
      }else{
        dispatch(snackbarRequest({message:res.data.message,type:"error"}));
      }
    }
  };

  const takeRequiredValue = (arr, key) => {
    const modArr = arr?.map((obj) => {
      return obj[`${key}`];
    });
    if (modArr?.includes(undefined) || modArr?.includes(null)) {
      return [];
    } else {
      return modArr;
    }
  };

  const getSingleConfigueValue = async () => {
    try {
      const res: any = await configueService.getSingleConfigListApi({
        config_id: match?.params?.config_id,
      });
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(UpdatefeatureConfigDetails(res?.data?.data?.[0]));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (updateConfigDetails?.id) {
      setValue(
        "class_list",
        takeRequiredValue(updateConfigDetails?.class_list, "class_id")
      );
      setValue(
        "academic_year_list",
        takeRequiredValue(
          updateConfigDetails?.academic_year_list,
          "academic_year_id"
        )
      );
      setValue(
        "batch_list",
        takeRequiredValue(updateConfigDetails?.batch_list, "batch_id")
      );
      setValue(
        "college_list",
        takeRequiredValue(updateConfigDetails?.college_list, "college_id")
      );
      setValue(
        "reservation_status_list",
        takeRequiredValue(
          updateConfigDetails?.registation_status_list,
          "registration_status_id"
        )
      );
      setValue(
        "sub_batch_list",
        takeRequiredValue(updateConfigDetails?.sub_batch_list, "sub_batch_id")
      );
      setValue(
        "college_list",
        takeRequiredValue(updateConfigDetails?.college_list, "college_id")
      );
      setValue(
        "is_global_config",
        updateConfigDetails?.is_global_config == true ? "true" : "false"
      );
      setValue(
        "is_early_bird_config",
        updateConfigDetails?.is_early_bird_config == true ? "true" : "false"
      );
      setValue("platform_list", updateConfigDetails?.platform_list);
      setJsonData(updateConfigDetails?.config_json);
    } else if (data?.length == 0) {
      getSingleConfigueValue();
    }
  }, [updateConfigDetails]);

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "sub_batch_list" && listData?.length == 0) {
      setValue("sub_batch_list", []);
    }
  };

  const updateMetaDataMs = async (
    listData,
    metaKey,
    listData2,
    metaKey2,
    listData3,
    metaKey3
  ) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    metadataList[metaKey2] = listData2;
    metadataList[metaKey3] = listData3;
    await dispatch(metadataSuccess(metadataList));
    setFetchAll(false);
  };

  const updateClassIdDep = async () => {
    let metadataList = { ...metaData };
    metadataList["batch_list"] = [];
    metadataList["sub_batch_list"] = [];
    await dispatch(metadataSuccess(metadataList));
    setValue("batch_list", []);
    setValue("sub_batch_list", []);
  };

  useEffect(() => {
    //aplist
    if (
      fetchAll &&
      watch()?.class_list?.length > 0 &&
      watch()?.batch_list?.length > 0
    ) {
      (async () => {
        const call1: any = await getCollegeListData("", {});
        const call2 = await getBatchFromClassIdForMs(
          watch()?.class_list,
          updateMetaDataMs
        );
        const call3 = await getSubBatchFromBatchIdForUp(
          watch()?.batch_list,
          updateMetaDataMs
        );
        if (call1) {
          updateMetaDataMs(
            call1[0],
            call1[1],
            call2[0],
            call2[1],
            call3[0],
            call3[1]
          );
        } else {
          updateMetaDataMs(
            [],
            "college_list",
            call2[0],
            call2[1],
            call3[0],
            call3[1]
          );
        }
      })();
    }
  }, [watch()?.class_list, watch()?.batch_list]);

  useEffect(() => {
    if (!fetchAll) {
      if (watch()?.class_list?.length > 0) {
        setTimeout(() => {
          getBatchFromClassId(watch()?.class_list, updateMetaData);
        }, 1000);
      } else {
        updateClassIdDep();
      }
    }
  }, [watch()?.class_id]);

  useEffect(() => {
    if (!fetchAll) {
      setTimeout(() => {
        getSubBatchFromBatchId(watch()?.batch_list, updateMetaData);
      }, 1000);
    }
  }, [watch()?.batch_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.sub_batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "sub_batch_list",
        watch()?.sub_batch_list,
        "sub_batch_id"
      );
      let isEqual = areArraysEqual(watch()?.sub_batch_list, matchedVales);
      if (!isEqual) {
        setValue("sub_batch_list", matchedVales);
      }
    }
  }, [metaData?.sub_batch_list]);
  
  useEffect(()=>{
    if(watch()?.is_global_config=="true"){
      setValue("is_early_bird_config", "false")
    }
  },[watch()?.is_global_config])

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 3000);
  }, []);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {match?.url?.includes("duplicate") ? "duplicate" : "Update"} configue
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createConfigProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: 1 }}>
          <Button
            variant="contained"
            sx={{
              width: "160px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 35px 7px 35px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={handleClick}
            //   disabled={buttonDisableState}
            disabled={
              !isFieldsValidate(errors, createConfigMandatoryFields, getValues)
            }
          >
            {match?.url?.includes("duplicate") ? "Save as New" : "Update"}
          </Button>
        </ContentRight>
        <Box sx={{ padding: "20px" }}>
          <JSONInput
            id="json-editor"
            placeholder={jsonData}
            locale={locale}
            height="550px"
            width="800px"
            onChange={handleJsonChange}
            style={{
              outerBox: { fontSize: "40px" },
              container: { fontSize: "40px" },
              text: { fontSize: "40px" },
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};
