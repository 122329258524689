import useReactHookForm from "hooks/useReactHookForm";
import { Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useHistory } from "react-router-dom";
import SectionTable from "shared/organisms/tables/table";
import {
  createProgramInitialValues,
  createProgramMandatoryFields,
  searchProgramMandatoryFields,
  createProgramProperties,
  ProgramProperties,
  createProgramValidationSchema,
} from "./helper";
import {
  emptyProgramData,
  StudentProgramLookupRequest,
  UpdateSingleStdProgramDetails,
} from "pages/configue/slices/student-program-slice";
import { table } from "./helper";
import NoDataFound from "shared/molecules/no-data";
import { getBatchListData } from "utils/metadata-split/metaDataApiFetch";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { isEmpty } from "utils/helpers";
import Loader from "pages/build-test/loader";
import { getProgramFromClassId } from "utils/metadata-split/depedentApiFetch";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { hasLengthGreaterThanZero } from "pages/configue/constants";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";

export const CreateStdProgram = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );
  const { data, error, loading } = useAppSelector(
    (state) => state.studentProgram
  );
  const [programDetails, setProgramDetails] = useState<any>([]);
  const [isEnabled, setisEnabled] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
  };

  const handleCreateProgram = async () => {
    const reqBody = {
      id: "id", // backend requir id for create
      class_list: watch()?.class_list,
      match_type: watch()?.match_type,
      match_id_list: watch()?.match_id_list,
      program_id: watch()?.program_id,
    };
    const res: any = await configueService.createProgramLookupApi(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
     history.replace(`/configue/student_program_lookup`);
    }else {
      dispatch(snackbarRequest({message:res.data.message,type:"error"}));
    }
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleProgram = { ...item };
      singleProgram["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleProgram["program_id"] = takeRequiredValue(
        item?.program_list,
        "program_id"
      );
      singleProgram["program_name"] = takeRequiredValue(
        item?.program_list,
        "program_name"
      );
      return singleProgram;
    });
    setProgramDetails(tableData);
  };

  const convertArrayToString = (arr) => {
    const params = {};
    let payloadString = "";
    if (typeof arr == "string") {
      return arr;
    } else if (arr?.length > 0) {
      arr.forEach((sateid, i) => {
        if (i == arr.length - 1) {
          payloadString = payloadString + sateid;
        } else {
          payloadString = payloadString + sateid + ",";
        }
      });
      return payloadString;
    }
  };

  const handleEdit = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id?.id,
      id: std_program_id?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(`/configue/student_program_lookup/edit/${std_program_id?.id}`);
    }
  };

  const handleCopy = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id?.id,
      id: std_program_id?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(
        `/configue/student_program_lookup/duplicate/${std_program_id?.id}`
      );
    }
  };

  // onSearch 
  const handleSearch = () => {
    setisEnabled(true);
    const payload = {
      class: convertArrayToString(watch()?.class_list),
      match_type: watch()?.match_type,
      match_id: watch()?.match_id_list,
      limit: 200,
      offset: 0,
      exact_match:true,
      boolean_operation:"AND"
    };
    dispatch(StudentProgramLookupRequest(payload));
    getProgramWithClass();
  };

  // to convert updated data into table data
  const convertUpdatedDataToTableData = (updateddata) => {
    const tableData = updateddata.map((item, i) => {
      let singleProgram = { ...item };
      singleProgram["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleProgram["program_id"] = takeRequiredValue(
        item?.program_list,
        "program_id"
      );
      singleProgram["program_name"] = takeRequiredValue(
        item?.program_list,
        "program_name"
      );
      return singleProgram;
    });
    setProgramDetails(tableData);
  };

  const handleSubmit = () => {
    const programId = watch()?.program_id;
    if (programId) {
      const updatedData = data.filter((item) => item?.program_list[0]?.program_id === programId);
      convertUpdatedDataToTableData(updatedData)
    } else{
       if (data.length > 0 && isEnabled) {
        // covert data intotable formate
        convertDataToTableData();
      }else{
        setProgramDetails([]);
      }
    }
  };


  const getProgramWithClass=async()=>{
    setValue("program_id", "");
   await getProgramFromClassId( watch()?.class_list,updateMetaData);
  }

  useEffect(() => {
    setTimeout(async () => {
      if (watch()?.match_type == "batch") {
        const call1 = await getBatchListData("", {});
        if (call1) {
          updateMetaData(call1[0], call1[1]);
        } else {
          updateMetaData([], "batch_list");
        }
      }
      setValue("match_id_list", []);
    }, 1000);
  }, [watch()?.match_type]);

  useEffect(()=>{
   dispatch(emptyProgramData())
  },[])
  
  useEffect(() => {
    if (data.length > 0 && isEnabled) {
      // covert data intotable formate
      convertDataToTableData();
    }else{
      setProgramDetails([]);
    }
  }, [data]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Program
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createProgramProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: 1 }}>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "-52px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={handleSearch}
            disabled={
             // !isFieldsValidate(errors, searchProgramMandatoryFields, getValues)
             hasLengthGreaterThanZero(watch(), "program_id")
            }
          >
            Search
          </Button>
        </ContentRight>

        {/* use to get courses based on class_id */}
        {/* after-search */}
        {isEnabled && (
          <div>
            <Grid container spacing={2} display={""} sx={{ mt: 1 }} >
              <DynamicForm
                fields={ProgramProperties(watch())}
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />

        <Grid item>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "0px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "15px",
              }}
              onClick={handleSubmit}
              // disabled={
              //   !isFieldsValidate(errors, createProgramMandatoryFields, getValues)
              // }
            >
              Submit
            </Button>
         </Grid>

            </Grid>
            <ContentRight sx={{ mt: 1 }}>
            <Button
              variant="contained"
              sx={{
                width: "180px",
                height: "40px",
                marginTop: "-75px",
                padding: "7px 20px 7px 20px",
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "15px",
              }}
              onClick={handleCreateProgram}
              disabled={
                !isFieldsValidate(errors, createProgramMandatoryFields, getValues)
              }
            >
              Create New Program
            </Button>
            </ContentRight>
          </div>
        )}
         {loading && <Loader />}
        {!loading && !isEmpty(programDetails) && (
          <CollapsibleTable
          data={programDetails}
          table={table}
          id="id"
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          rowsPerPageCount={20}
          paginationSegmentApi="false"
          hasPageApiCall={false}
          showPaginationFooter={false}
        />
          )}
          {!loading && isEmpty(programDetails)&& isEnabled &&(
            <div>
            <NoDataFound></NoDataFound>
            </div>
          )}
      </Paper>
    </div>
  );
};
