import { Box } from "@mui/system";
import "./styles.css";

export default function Loader() {
  return (
    <>
      <Box
        sx={{
          borderLeft: "solid 1px #ced4da",
          borderRight: "solid 1px #ced4da",
          borderBottom: "solid 1px #ced4da",
          borderRadius: "0px 0px 8px 8px",
          backgroundColor: "#fff",
        }}
      >
        <div className="cont" style={{ height: "100vh" }}>
          <div className="lds-dual-ring"></div>
          <span>
            <b>Please Wait</b>
          </span>
        </div>
      </Box>
    </>
  );
}
