import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./styles";

import TableTitles from "./table-titles";
import TableData from "./table-data";

function TableMainBody(props: any) {
  const {
    row,
    handleEdit,
    handleDelete,
    handleCopy,
    id,
    name,
    table,
    handleStore,
    handleDeliver,
    handleAnswer,
    handlePreview,
    displayFullstring,
    viewSecondaryData,
  } = props;
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const { primaryData, secondaryData } = table;
  return (
    <>
      <React.Fragment>
        <TableRow
          sx={{
            "& > *": {
              borderBottom: "unset",
              fontWeight: row.makeRowBold && "bold",
            },
          }}
          className={styles.mainTableBody}
        >
          <TableData
            data={primaryData}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handlePreview={handlePreview}
            row={row}
            id={id}
            name={name}
            displayFullstring={displayFullstring}
          />

          {viewSecondaryData == "true" && (
            <TableCell align="right">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}
        </TableRow>

        <TableRow>
          <TableCell style={{ padding: 0, width: "100%" }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{ margin: 1, backgroundColor: "#f7f6f6", width: "98.5%" }}
              >
                <Typography variant="h6" gutterBottom component="div">
                  {/* Full Details */}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className={styles.subTableHeading}>
                      <TableTitles data={secondaryData} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={row.id} className={styles.subTableBody}>
                      <TableData
                        data={secondaryData}
                        handleEdit={handleEdit}
                        handleCopy={handleCopy}
                        handlePreview={handlePreview}
                        row={row}
                        id={id}
                        name={name}
                        displayFullstring={displayFullstring}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
}

export default TableMainBody;
