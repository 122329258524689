import useReactHookForm from "hooks/useReactHookForm";
import {
  UpdateAppDetailsMandatoryFields,
  updateAppDetailsForm,
  updateAppDetailsIntialValues,
  updateAppValidationSchema,
} from "./helper";
import { Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import configueService from "pages/configue/service";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { UpdateSingleAppDetails } from "pages/configue/slices/force-update-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";

export const UpdateVersion = () => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    updateAppDetailsIntialValues,
    updateAppValidationSchema,
    ""
  );
  const match: any = useRouteMatch();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data, updateAppDetails } = useAppSelector(
    (state) => state.forceUpdateDetails
  );

  const handleUpdate = async () => {
    const reqBody = {
      version_id: `${watch()?.version_id}`,
      version_name: watch()?.version_name,
      version_number: watch()?.version_number,
      //is_force_update : watch()?.is_force_update=="true"?true:false,
      is_force_update: watch()?.is_force_update,
    };
    const res: any = await configueService.updateAppVersionApi(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "updated successfully" }));
      history.replace(`/configue/force_update`);
    }else{
      dispatch(snackbarRequest({message:res.data.message,type:"error"}));
    }
  };

  const getSingleVersionValue = async () => {
    try {
      const res: any = await configueService.getSingleAppVersionListApi({
        version_id: match?.params?.version_id,
      });
      if (res?.status === 200 && res?.data?.status === "success") {
        dispatch(UpdateSingleAppDetails(res?.data?.data[0]));
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (updateAppDetails?.version_id) {
      setValue("version_id", updateAppDetails?.version_id);
      setValue("platform", updateAppDetails?.platform);
      setValue("app", updateAppDetails?.app);
      setValue("version_number", updateAppDetails?.version_number);
      setValue(
        "version_name",
        updateAppDetails?.version_name == null
          ? "null"
          : updateAppDetails?.version_name
      );
      setValue(
        "is_force_update",
        updateAppDetails?.is_force_update == true ? "true" : "false"
      );
      setRender(!render);
    } else if (data?.length == 0) {
      getSingleVersionValue();
      // history.replace(`/configue/force_update`);
    }
  }, [updateAppDetails]);

  return (
    <div>
      <div>
        <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Update App Versions
          </Typography>
          <Grid container spacing={2}>
            <DynamicForm
              fields={updateAppDetailsForm}
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
            <ContentRight sx={{ mt: -7 }}>
              <Button
                variant="contained"
                sx={{
                  width: "80px",
                  height: "40px",
                  marginTop: "15px",
                  padding: "7px 42.5px 7px 48.5px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={handleUpdate}
                disabled={
                  !isFieldsValidate(
                    errors,
                    UpdateAppDetailsMandatoryFields,
                    getValues
                  )
                }
              >
                Update
              </Button>
            </ContentRight>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};
