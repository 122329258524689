import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  mainTableHeading: {
    "& .css-12qr4rk-MuiTableCell-root": {
      textAlign: "left",
      padding: "12px 0px 12px 16px",
    },
  },
  mainTableBody: {
    "& .css-1wsfaj7-MuiTableCell-root": {
      textAlign: "left",
      padding: "0px 0px 0px 16px",
    },
    "& .css-1tf82ew-MuiTableCell-root": {
      padding: "12px",
      textAlign: "center",
    },
    "& .css-l7lkss-MuiTableCell-root":{
      padding:0,
    },
    "& .css-ilyfv3-MuiTableCell-root":{
      padding:'0px 0px 0px 10px',
    },
    "& .css-6p2ov8-MuiTableCell-root":{
      padding:'0px 0px 0px 10px'
    },
    "& .css-1fmh4pw-MuiTableCell-root":{
      padding:0
    },
    "& .css-6q0s7z-MuiTableCell-root":{
      padding:0
    },
    "& .css-1maxrc2-MuiTableCell-root":{
      padding:0
    },
    "& .css-576zks-MuiTableCell-root":{
      padding:0
    },
    "& .css-d8fbq6-MuiTableCell-root":{
      padding:0
    },
  },
  subTableHeading:{
      "& .css-1lf7ypl-MuiTableCell-root":{
        textAlign: "left",
      }
  },
  subTableBody:{
      "& .css-14edn49-MuiTableCell-root":{
        textAlign: "left",
      }
  },
  tableCell:{
    "& .css-l7lkss-MuiTableCell-root":{
      padding:0,
      backgroundColor:'red'
    }
  }
});
