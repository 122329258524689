import request from "utils/axios/service";

export const convertToLabelValueFormat = (field: any, data) => {
  if (!data || data.length === 0) return [];
  return data.map((item: any) => ({
    label: item[field.keyValue.label],
    value: item[field.keyValue.value],
  }));
};

export const getDataFromApi = async (field, values, cb) => {
  try {
    if (field.requestList) {
      const payloadData = await field.requestList(values);
      console.log("payload sent", payloadData);
      const res: any = await request.get(field.endpoint, payloadData);
      console.log("gtet check api call ",field.id)
      if (res && res.status === 200 && res.data.status === "success") {
        // NOTE added for chapter list
        if (field.id === 'chapter_id' && res.data.data?.chapter_list) {
          const dummyRes = await cb(res.data.data?.chapter_list);        
          return convertToLabelValueFormat(field, res.data.data?.chapter_list);
        }
        const dummyRes = await cb(res.data.data); 
        return convertToLabelValueFormat(field, res.data.data);
      }
    } else {
      const res: any = await request.get(field.endpoint, values);
      if (res && res.status === 200 && res.data.status === "success") {
        const dummyRes = await cb(res.data.data);
        return convertToLabelValueFormat(field, res.data.data);
      }
    }
  } catch (error) {
    throw error;
  }
};
