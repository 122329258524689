import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import FormHelperText from "@mui/material/FormHelperText";
import { StyledTextFiled } from "./styles";

interface INumberFieldProps {
  field: any;
  control: any;
  errors: any;
  name?: any;
  indexNumber?: any;
  setValue?: any;
}

const NumberField = ({
  field,
  control,
  errors,
  name,
  indexNumber,
  setValue,
}: INumberFieldProps) => {
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;

  const handleChange = (event: any, onChange: any) => {
    onChange(event.target.value);

    if (field.dependentFields)
      field.dependentFields.map((item: string) => setValue(`${item}`, ""));
  };

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <StyledTextFiled
              $readOnly={field.readOnly}
              onChange={(e) => handleChange(e, onChange)}
              value={value}
              label={field.label}
              type="number"
              placeholder={
                field.placeholder ? field.placeholder : "Enter Number.."
              }
              className={`${field.className}`}
              error={multiFormError ? multiFormError : singleFormError}
              variant="outlined"
              InputProps={{ readOnly: field.readOnly ? field.readOnly : false }}
              required={field.required}
              disabled={field.disabled}
              fullWidth
              {...field.extraUiProps}
              size="small"
            />
            {field.formHelperText && (
              <FormHelperText sx={{ ml: 1 }}>
                {field.formHelperText}
              </FormHelperText>
            )}
            {errors && (singleFormError || multiFormError) && (
              <FieldError
                field={field}
                errors={errors}
                name={name}
                indexNumber={indexNumber}
              />
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default NumberField;
