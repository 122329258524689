import { Grid, TextField } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";

interface ITextFieldProps {
  field: any;
  control: any;
  errors: any;
  watch: any;
}

const TextFieldComponent = ({
  field,
  control,
  errors,
  watch,
}: ITextFieldProps) => {
  const [placeholderText, setPlaceHolderText] = useState<any>("");
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );

  useEffect(() => {
    if (field.placeholderText) {
      const filterPlace = metaData[field.list].filter((item) => {
        // console.log("console for item",item.value)
        if (watch().property == item.value) {
          setPlaceHolderText(item.placeholder);
          return item.placeholder;
        }
      });

      console.log("see for placeholder text", filterPlace);
    }

    // const d=localStorage.getItem('metadata')
  }, [watch().property, updatedAt]);
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <TextField
              onChange={onChange}
              value={value || ""}
              //   label={watch().property ? `Please enter ${watch().property.split("_").join(" ")}` : field.label}
              type="text"
              // placeholder={
              //   watch().property
              //     ? `Please enter ${watch().property.split("_").join(" ")}`
              //     : field.placeholder
              // }
              placeholder={
                watch().property ? placeholderText : field.placeholder
              }
              className={field.className}
              fullWidth
              error={errors && errors[field.id]?.message}
              variant="outlined"
              required={field.required}
              sx={{ width: "100%" }}
              size="small"
              disabled={field.disabled}
            />
            {errors && errors[field.id]?.message && (
              <FieldError field={field} errors={errors} />
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default TextFieldComponent;
