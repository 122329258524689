import { useRef, useState } from "react";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import awsConfig from "aws-config";
import Amplify, { Storage } from "aws-amplify";
import TextField from "@mui/material/TextField";
import { setItem } from "utils/helpers";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { withStyles, makeStyles } from "@mui/styles";
const sanitize = require("sanitize-filename");

type UploadProps = {
  onChange: any;
  fields: any;
  getValues: any;
  classes: any;
  value: any;
  className: string;
  watch?: any;
  errors: any;
  nameValue?: any;
  indexNumber?: any;
};
export interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
}
const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: "red",
    color: "white",
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: "green",
    color: "white",
  },
};

// Amplify.configure({
//   Auth: {
//     identityPoolId: awsConfig.identity_pool_id,
//     region: awsConfig.aws_cognito_region,
//   },
// });

const uesStyles = makeStyles({
  helpertext: {
    "& .css-pjy7bd-MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
});

Storage.configure({
  AWSS3: {
    bucket: awsConfig.aws_user_files_s3_bucket,
    region: awsConfig.aws_user_files_s3_bucket_region, //OPTIONAL -  Amazon service region
  },
});

const Upload = ({
  onChange,
  fields,
  getValues,
  classes,
  className,
  value,
  watch,
  errors,
  nameValue,
  indexNumber,
}: UploadProps) => {
  const styles = uesStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [fileName, setFileName] = React.useState<{ [key: string]: string }>({});
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const [imagesNamesList, setImagesNamesList] = useState<string[]>([]);

  const [state, setState] = React.useState<any>({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const { vertical, horizontal, open } = state;
  //open snackbar
  const isReqFieldsNotEmpty = (obj: {}) => {
    if (obj) {
      return Object.keys(obj).every((key) => getValues(key) !== "");
    }
    return false;
  };

  //close snackbar
  const handleClose = (event: any, reason?: any) => {
    if (reason === "clickaway") {
      return;
    }
    setState((prev) => ({ ...prev, open: false, message: "" }));
  };
  const requiredFieldsToEnable = (obj: {}) => {
    let fieldNames: string[] = [];
    if (obj) {
      Object.keys(obj).forEach((key) => fieldNames.push(key));
      return (
        <span style={{ fontSize: "12px" }}>
          {fieldNames.join(",")} is required to enable upload
        </span>
      );
    }
    return "";
  };

  const getPath = () => {
    const values = getValues();
    let path = "others";
    console.log("check values", values);
    if (fields.feature === "assignments") {
      path = `assignments/${values.class_id}/course/${values.subject_id}/chapter`;
    } else if (fields.feature === "schedule") {
      path = `schedule/${values.class_id}/${values.course_id}/`;
    } else if (fields.feature === "term-exams") {
      path = `descriptive-exam/${values.class_id}`;
    } else if (fields.feature === "learn") {
      path = `learn/resources/${values.class_id}/${
        values.course_id || "course"
      }/${values.subject_id || "subject"}/${
        values.topic_id || values.chapter_id || "chapter"
      }`;
    } else if (fields.feature === "build-test") {
      path = `build-test`;
    } else if (fields.feature === "fun-sheet") {
      path = `fun-sheet`;
    } else if (fields.feature === "miscellaneous") {
      path = `instructions`;
    }
    return path;
  };

  const handleCapture = async (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    let file = event.target.files[0];
    // let nxtFileName = file?.name;
    let nxtFileName =
      file &&
      `${new Date().getTime()}${file.name.slice(file.name.lastIndexOf("."))}`;
    let extensionType =
      file &&
      event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );
    if (fields.isSameFileName) {
      nxtFileName = sanitize(file.name);
    }
    let fileSize = 50;
    if (fields.size) fileSize = fields.size;
    let isFileSizeOverflow =
      file && !(Math.ceil(file.size / 1000000) <= fileSize);
    if (
      file &&
      !isFileSizeOverflow &&
      fields.fileType.includes(`.${extensionType}`)
    ) {
      setShowLoading(true);
      try {
        let folderName = getPath();
        const result = await Storage.put(`${folderName}/${nxtFileName}`, file, {
          contentType: file.type,
          completeCallback: (e) => {
            console.log(`Successfully uploaded ${e.key}`);
          },
          progressCallback(progress) {
            console.log(
              `Uploading progress: ${progress.loaded}/${progress.total}`
            );
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        })
          .then((e) => {
            console.log(`Successfully uploaded ${e.key}`);
            if (e.key) {
              setShowLoading(false);
              setState((prev) => ({
                ...prev,
                open: true,
                message: "Successfully Uploaded",
              }));
              setFileName((prev) => ({
                ...prev,
                [event.target.name]: nxtFileName,
              }));
              setImagesNamesList([...imagesNamesList, nxtFileName]);
              let fileName: any = `https://${
                awsConfig.aws_user_files_s3_bucket
              }.s3.${
                awsConfig.aws_user_files_s3_bucket_region
              }.amazonaws.com/public/${folderName}/${encodeURIComponent(
                nxtFileName
              )}`;
              onChange(fileName);
              if (fileName) {
                setImages([...images, fileName.split("?X-Amz")[0]]);
              }
            }
          })
          .catch((err) => console.log("got error while uploading", err));

        event.target.value = null;
      } catch (err) {
        setShowLoading(false);
        console.log(`PUT in error${err}`);
      }
    } else {
      setShowLoading(false);
      let message = "Please select valid file type";
      if (isFileSizeOverflow)
        message = `File size is more than ${fields.size}, Please select valid file`;
      setState((prev) => ({
        ...prev,
        open: true,
        message: message,
      }));
    }
  };

  const len = value ? value.split("/").length : 0;
  return (
    <>
      {showLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={showLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className={`file-styles ${className}`}>
        <label htmlFor="contained-button-file" className="file-styles">
          <input
            type="file"
            name={
              nameValue ? `${nameValue}.${indexNumber}.${fields.id}` : fields.id
            }
            onChange={handleCapture}
            disabled={fields.fields && !isReqFieldsNotEmpty(fields.fields)}
            style={{ display: "none" }}
            accept={fields.acceptTypes}
            ref={fileInputRef}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-between",
              alignItems: "center",
            }}
          >
            <TextField
              variant="standard"
              name={
                nameValue
                  ? `${nameValue}.${indexNumber}.${fields.id}`
                  : fields.id
              }
              label={fields.label}
              disabled
              InputLabelProps={{ shrink: true }}
              helperText={`(max file size: ${
                fields.size ? fields.size : "50"
              }MB)`}
              // value={value && value.split("?X-Amz")[0].split("/").pop()}
              value={value && value?.split("/")[len - 1]}
              sx={{ width: "80%", mt: "-8px" }}
            />
            <Button
              size="small"
              startIcon={<CloudDownloadIcon />}
              aria-label="upload pdf"
              component="span"
              onClick={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
              sx={{
                color: `${
                  getValues(
                    nameValue
                      ? `${nameValue}.${indexNumber}.${fields.id}`
                      : fields.id
                  )
                    ? "green"
                    : isReqFieldsNotEmpty(fields.fields)
                    ? "black"
                    : "#3f51b5"
                }`,
                marginBottom: "25px",
                marginLeft: "10px",
              }}
              disabled={fields.fields && !isReqFieldsNotEmpty(fields.fields)}
            >
              BROWSE
            </Button>
          </div>
        </label>
        {!isReqFieldsNotEmpty(fields.fields) && (
          <p style={{ color: "red", textAlign: "start" }}>
            {requiredFieldsToEnable(fields.fields)}
          </p>
        )}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={state.message}
          anchorOrigin={{ vertical, horizontal }}
          ContentProps={{
            "aria-describedby": "message-id",
            className: state.message.includes("Successfully Uploaded")
              ? classes.snackbarStyleViaNestedContent
              : classes.snackbarStyleViaContentProps,
          }}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(Upload);
