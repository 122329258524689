import * as Yup from "yup";
import { endpointsMd } from "utils/metadata-split/constant";

export type createStdCourseType = {
  academic_year_list: string[];
  reservation_status_list: string[];
  class_list: string[];
  batch_list: string[];
  state_list: string[];
  college_list: string[];
  board_list: string[];
  course_id: string;
  is_secondary_course: boolean | string;
  is_default_course: boolean | string;
  is_early_bird_course: boolean | string;
};

export const createStdCourseInitialValues: createStdCourseType = {
  academic_year_list: [],
  reservation_status_list: [],
  class_list: [],
  batch_list: [],
  state_list: [],
  college_list: [],
  board_list: [],
  course_id: "",
  is_secondary_course: "false",
  is_default_course: "false",
  is_early_bird_course: "false",
};

export const searchStdCourseMandatoryFields = [
  { id: "academic_year_list" },
  { id: "reservation_status_list" },
  { id: "class_list" },
  // { id: "batch_list" },
  // { id: "course_id" },
];

export const createStdCourseMandatoryFields = [
  { id: "academic_year_list" },
  { id: "reservation_status_list" },
  { id: "class_list" },
  { id: "batch_list" },
  { id: "course_id" },
];

export const createStdCourseValidationSchema = Yup.object().shape({
  academic_year_list: Yup.array()
    .required("value required")
    .min(1, "value required"),
  reservation_status_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
  // reservation_status_id:Yup.array().of(Yup.string()).required("value required"),
  class_list: Yup.array().min(1, "value required").required("value required"),
  batch_list: Yup.array().min(1, "value required").required("value required"),
  course_id: Yup.string().required("course_id is required"),
});

export const createStdCoursePropertiesFirst = [
  {
    id: "academic_year_list",
    type: "multi-select",
    label: "Academic Year * ",
    className: "select-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
  },
  {
    id: "reservation_status_list",
    type: "multi-select",
    label: "Registration Status *",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "reservation_status_list",
    keyValue: {
      label: "registration_status_name",
      value: "registration_status_id",
    },
  },
  {
    id: "class_list",
    type: "multi-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["batch_list"],
    dependentFlds: {
      class_course_batch_data: "class_list",
    },
  },
];

export const createStdCoursePropertiesSecond=(watch) =>{
   return [
  {
    id: "batch_list",
    type: "multi-select-dependency",
    label: "Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    required: true,
    list: "batch_list",
    dependencyLabel: "Please select Class",
    keyValue: { label: "batch_name", value: "batch_id" },
    fieldKeyLists: {},
    dependentFields: [],
    dependentFlds: {
      batch_list: "batch_list",
    },
  },
  {
    id: "state_list",
    type: "multi-select-dependency",
    label: "State ",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: ["college_list"],
    dependentFlds: {
      state_list: "state_list",
      //college_list: "college_id",
    },
  },
  {
    id: "college_list",
    type: "multi-select-dependency",
    label: "College ",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    list: "college_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "college_name", value: "college_id" },
    fieldKeyLists: {},
  },
  {
    id: "board_list",
    type: "multi-select-dependency",
    label: "Board",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "board_list",
    keyValue: { label: "board_name", value: "board_id" },
  },
  {
    id: "course_id",
    type: "single-select-dependency",
    label: "Course *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    // isDependent: true,
    options: [],
    list: "course_list",
    keyValue: { label: "course_name", value: "course_id" },
    fieldKeyLists: {},
    dependentFields: [],
  },
  {
    id: "is_secondary_course",
    type: "normal-select",
    label: "is Secondary Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone:true
  },
  {
    id: "is_default_course",
    type: "normal-select",
    label: "is Default Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    readOnly: watch.is_secondary_course === "true" ? true : false,
    hideNone:true
  },
  {
    id: "is_early_bird_course",
    type: "normal-select",
    label: "is Early Bird Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    readOnly: watch.is_secondary_course === "true" ? true : false,
    hideNone:true
  },
]
};

export const CourseProperties =  (watch: any) =>{
  return [
    {
        id: "course_id",
        type: "single-select-dependency",
        label: "Course *",
        className: "select-styles",
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        required: true,
        // isDependent: true,
        onlyApiCall: true,
        requestList: async (payload) => {
          return {class_id: watch?.class_list};
        },
        endpoint: endpointsMd.GET_COURSE_LIST,
        options: [],
        list: "course_list",
        keyValue: { label: "course_name", value: "course_id" },
        fieldKeyLists: {},
        dependentFields: [""],
      }
]};

export const table = {
  primaryData: [
    {
      title: "Course",
      value: "course_list",
      // name: "academic_year_name",
      type:"configue",
      width:"15%"
    },
    {
      title: "academic year",
      value: "year_list",
      // name: "academic_year_name",
      type:"configue",
    },
    {
      title: "state",
      value: "state_list",
       type:"configue",
         width:"20%"
    },
    {
      title: "college",
      value: "college_list",
     // type:"configue",
    },
    {
      title: "class",
      value: "class_list",
      type:"configue",
    },
    {
      title: "batch",
      value: "batch_list",
      type:"configue",
    },
    {
      title: "Actions",
      width: "5%",
    },
  ],
  secondaryData: [
    {
      title: "Registration",
      value: "registration_status_list",
      type:"configue",
    },
    {
      title: "Board",
      value: "board_list",
    },
    {
      title: "is_default_course",
      value: "is_default_course",
    },
    {
      title: "is_early_bird_course",
      value: "is_early_bird_course",
    },
    {
      title: "Created At",
      value: "created_at",
    },
    {
      title: "created By",
      value: "created_username",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
    },
  ],
};

export const tableCreate = {
  primaryData: [
    {
      title: "Course",
      value: "course_list",
      // name: "academic_year_name",
      type:"configue",
      width:"20%"
    },
    {
      title: "state",
      value: "state_list",
        type:"configue",
         width:"20%"
    },
    {
      title: "college",
      value: "college_list",
      type:"configue",
    },
    {
      title: "batch",
      value: "batch_list",
      type:"configue",
    },
    {
      title: "Board",
      value: "board_list",
    },
    {
      title: "Created At",
      value: "created_at",
    },
    {
      title: "Actions",
      width: "5%",
    },
 
  ],
  secondaryData: [
    {
      title: "is_default_course",
      value: "is_default_course",
    },
    {
      title: "is_early_bird_course",
      value: "is_early_bird_course",
    },
    {
      title: "created By",
      value: "created_username",
    },
    {
      title: "Updated At",
      value: "updated_at",
      name: "update",
    },
    {
      title: "Updated By",
      value: "updated_username",
      name: "update",
    },
  ],
};

export const updateStudentCourseProperties =(watch)=>{
 return[
  {
    id: "academic_year_list",
    type: "multi-select",
    label: "Academic Year * ",
    className: "select-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
  },
  {
    id: "reservation_status_list",
    type: "multi-select",
    label: "Registration Status *",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "reservation_status_list",
    keyValue: {
      label: "registration_status_name",
      value: "registration_status_id",
    },
  },
  {
    id: "class_list",
    type: "multi-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["batch_list"],
    dependentFlds: {
      class_course_batch_data: "class_list",
    },
  },
  {
    id: "batch_list",
    type: "multi-select-dependency",
    label: "Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    required: true,
    list: "batch_list",
    dependencyLabel: "Please select Class",
    keyValue: { label: "batch_name", value: "batch_id" },
    fieldKeyLists: {},
    dependentFields: [],
    dependentFlds: {
      batch_list: "batch_list",
    },
  },
  {
    id: "state_list",
    type: "multi-select-dependency",
    label: "State ",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: ["college_list"],
    dependentFlds: {
      state_list: "state_list",
      //college_list: "college_id",
    },
  },
  {
    id: "college_list",
    type: "multi-select-dependency",
    label: "College ",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    list: "college_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "college_name", value: "college_id" },
    fieldKeyLists: {},
  },
  {
    id: "board_list",
    type: "multi-select-dependency",
    label: "Board",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "board_list",
    keyValue: { label: "board_name", value: "board_id" },
  },
  {
    id: "course_id",
    type: "single-select-dependency",
    label: "Course *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    // isDependent: true,
    options: [],
    list: "course_list",
    keyValue: { label: "course_name", value: "course_id" },
    fieldKeyLists: {},
    dependentFields: [],
  },
  {
    id: "is_secondary_course",
    type: "normal-select",
    label: "is Secondary Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    hideNone:true
  },
  {
    id: "is_default_course",
    type: "normal-select",
    label: "is Default Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    readOnly: watch.is_secondary_course === "true" ? true : false,
    hideNone:true
  },
  {
    id: "is_early_bird_course",
    type: "normal-select",
    label: "is Early Bird Course",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
    readOnly: watch.is_secondary_course === "true" ? true : false,
    hideNone:true
  },
]};
