import { ReactNode } from "react";
import Grid from "@mui/material/Grid";

interface IProps {
  children: ReactNode;
  sx?: any;
  onClick?: any;
}
export default function ContentRight({ children, sx, ...rest }: IProps) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={sx}
      {...rest}
    >
      {children}
    </Grid>
  );
}
