import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { isAuthorized } from "utils/helpers";
import { ThemeProvider } from "@mui/material/styles";
import indigoTheme from "utils/themes/indigo";
import history from "utils/history";
import AppLayout from "shared/templates/app-layout";
import useStyles from "../styles";
import Amplify from "aws-amplify";
import awsConfig from "../aws-config";
import { showSnackBar } from "shared/molecules/snackbar/snackbar-slice";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { featureConfigRequest } from "./slices/feature-config-slice";
import SnackBar from "shared/molecules/snackbar/index";
import LoginService from "pages/login/service";
import Box from "@mui/material/Box";
import { publicRoutes, protectedRoutes } from "../routes";
import { metadataRequest, metadataSuccess, metadataUpdate } from "./slices/metadata-slice";
import LoaderModal from "shared/molecules/loader-modal";
import { getAcademicYearListData, getBoardListData, getClassListData, getCourseListData, getPatternMaskListData, getRegistrationStatusListData, getStateListData, getSubjectListData } from "utils/metadata-split/metaDataApiFetch";
import { gmailUserRoleRequest } from "pages/login/gmail-slice";

const loginService = new LoginService();

Amplify.configure({
  Auth: {
    identityPoolId: awsConfig.identity_pool_id,
    region: awsConfig.aws_cognito_region,
  },
});

const renderRoutes = (route, props, featureConfig) => {
  const RouteComponent = route.isPublic ? Route : PrivateRoute;
  if (featureConfig[route.featureConfigKey]) return <></>;
  return (
    <RouteComponent
      key={route.path}
      exact={route.exact}
      path={route.path}
      component={route.component}
      {...props}
    />
  );
};

function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const snackBarInfo: any = useAppSelector(showSnackBar);
  const[showComponent,setShowComponent]=useState(false);
  const[flagRole,setFlagRole]=useState(false);
  const { data: featureConfig } = useAppSelector(
    (state) => state.featureConfig
  );
  const { isLoading,data:metaData }: any = useAppSelector((state) => state.metadataReducer);
  const {profileData} = useAppSelector((state) => state.gmailLoginReducer);
  const {role:userRoleStr}=profileData;
  const userRole = userRoleStr?.split(",");
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOpen(snackBarInfo.isShow);
  }, [snackBarInfo]);

  useEffect(() => {
    dispatch(featureConfigRequest());
  }, []);

  const updateMetaData:any=async(listData,metaKey,listData2,metaKey2,listData3,metaKey3,listData4,metaKey4,listData5,metaKey5
    ,listData6,metaKey6,listData7,metaKey7,listData8,metaKey8)=>{
     let metadataList = {...metaData};
     metadataList[metaKey] = listData;
     metadataList[metaKey2] = listData2;
     metadataList[metaKey3] = listData3;
     metadataList[metaKey4] = listData4;
     metadataList[metaKey5] = listData5;
     metadataList[metaKey6] = listData6;
     metadataList[metaKey7] = listData7;
     metadataList[metaKey8] = listData8;
     metadataList["appDispatch"]=true;
    await dispatch(metadataSuccess(metadataList))
    setTimeout(() => {
      setShowComponent(true);
    }, 500);
   }

   const updateMetaDataKeyVise = async (listData, metaKey) => {
    let payload={
     key:metaKey,
     value:listData
    }
     dispatch(metadataUpdate(payload));
     
   };

   useEffect(()=>{
    const jwtToken = localStorage.getItem("token");
    if(jwtToken){
      dispatch(gmailUserRoleRequest({}))
    }
   },[localStorage.getItem("token")])


  const functionCallInitialApis=()=>{
  }

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    const fetchFromLogin = localStorage.getItem("fetchFromLogin");

    const callback = (metadata) => {
      dispatch(metadataSuccess(metadata));
    };


    if (jwtToken && !fetchFromLogin) {
      // dispatch(metadataRequest())
      // loginService.getMetaDataApi(callback);
      // functionCallInitialApis(updateMetaData);

      (async()=>{
        try{
         const call1:any= await getStateListData(updateMetaData);
         const call2:any= await  getBoardListData(updateMetaData);
         const call3:any= await  getSubjectListData(updateMetaData)
         const call4:any= await  getRegistrationStatusListData(updateMetaData)
         const call5:any= await  getClassListData(updateMetaData)
         const call6:any= await  getAcademicYearListData(updateMetaData)
         const call7:any= await  getCourseListData(updateMetaData)
         const call8: any = await getPatternMaskListData("");

         updateMetaData(call1[0],call1[1],call2[0],call2[1],call3[0],call3[1],call4[0],call4[1],call5[0],call5[1],call6[0],call6[1]
          ,call7[0],call7[1],call8[0],call8[1])
        }
        catch(error){
          console.log(error)
        }
      })()
    }
  }, [localStorage.getItem("token")]);

  // useEffect(()=>{

  // },[localStorage.getItem("fetchData")])

  useEffect(()=>{
  },[metaData])

  console.log("check is loading",isLoading)

  const isAuthorizedCheck=(route)=>{    
    if (isAuthorized() && showComponent && userRole.length>0) {
      if(route.user=="all"){
        return true
      }else if (userRole.includes(route.user)){
         return true
      }else {
        return false
      }
    }
  }

  return (
    <Box className={classes.spacing} sx={{ position: "relative", zIndex: 1 }}>
      <SnackBar
        open={open}
        message={snackBarInfo.value}
        type={snackBarInfo.type}
      />
      {(isLoading) && <LoaderModal message=" "  />}
      <div id="mathjax-loader" style={{ display: "none" }} />
      <ConnectedRouter history={history}>
        <ThemeProvider theme={indigoTheme}>
          {(featureConfig) && (
            <Switch>
              {publicRoutes.map((route) =>
                renderRoutes(route, {}, featureConfig)
              )}
              <Route
                path="*"
                render={(props) => (
                 <AppLayout>
                   <Switch>
                      {protectedRoutes.map((route) =>
                       isAuthorizedCheck(route) && renderRoutes(route, props, featureConfig)
                      )}
                    </Switch>
                  </AppLayout>
                )}
              />
            </Switch>
          )}
        </ThemeProvider>
      </ConnectedRouter>
    </Box>
  );
}

const PrivateRoute = (props: any) => {
  const isLoggedIn = isAuthorized();
  if (!isLoggedIn) return <Redirect to="/login" />;
  return <Route {...props} />;
};

export default App;






