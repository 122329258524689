import { Box, Button, Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Loader from "pages/build-test/loader";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import NoDataFound from "shared/molecules/no-data";
import { TypographyHeading } from "shared/molecules/typography-layout";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import {
  StudentCourseLookupRequest,
  UpdateSingleStdCourseDetails,
  emptyCourseData,
} from "pages/configue/slices/student-course-slice";
import { CourseLookUpFilter } from "./courseLokup-filter/CourseLookupFilter";
import { table } from "./helper";
import configueService from "pages/configue/service";
const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn:{
    backgroundColor:"#3f50b5",
    color:"white",
      width:"90px"
},
  BtnWrapper:{
    display: "flex",
    gap: "20px",
    justifyContent:"flex-end",
    paddingTop:"10px",
    paddingBottom:"10px",
}
}));

export const StudentCourseLookUp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [stdCourses, setStdCourses] = useState([]);
  const[limit,setLimit]=useState(10);
  const[offset,setOffset]=useState(0);
  const[searchPayload,setSearchPayload]=useState({})
  const { data, error, loading } = useAppSelector(
    (state) => state.studentCourseLookup
  );

  useEffect(() => {
    dispatch(StudentCourseLookupRequest({...searchPayload,limit,offset}));
    // dispatch(emptyCourseData());
  }, [offset,searchPayload]);

  const handleEdit = async (courseId) => {
    const res: any = await configueService.getSingleCourseLookup({
      lookup_id: courseId?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdCourseDetails(res?.data?.data?.[0]));
      history.push(`/configue/student_course_lookup/edit/${courseId?.id}`);
    }
  };

  const handleCopy = async (courseId) => {
    const res: any = await configueService.getSingleCourseLookup({
      lookup_id: courseId,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdCourseDetails(res?.data?.data?.[0]));
      history.push(`/configue/student_course_lookup/duplicate/${courseId}`);
    }
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleCourse = { ...item };
      singleCourse["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleCourse["course_list"] = takeRequiredValue(
        item?.course_list,
        "course_name"
      );
      singleCourse["year_list"] = takeRequiredValue(
        item?.year_list,
        "year_name"
      );
      singleCourse["state_list"] = takeRequiredValue(
        item?.state_list,
        "state_name"
      );
      singleCourse["batch_list"] = takeRequiredValue(
        item?.batch_list,
        "batch_name"
      );
      singleCourse["board_list"] = takeRequiredValue(
        item?.board_list,
        "board_name"
      );
      singleCourse["college_list"] = takeRequiredValue(
        item?.college_list,
        "college_name"
      );
      singleCourse["registration_status_list"] = takeRequiredValue(
        item?.registration_status_list,
        "registration_status_name"
      );
      return singleCourse;
    });
    setStdCourses(tableData);
  };

  useEffect(() => {
    if (data.length > 0) {
      convertDataToTableData();
    } else {
      setStdCourses([]);
    }
  }, [data]);

  return (
    <div>
      <ContentRight>
        <Link
          to="/configue/student_course_lookup/create"
          className={classes.label}
        >
          <Button variant="contained">Create New Course Lookup</Button>
        </Link>
      </ContentRight>
      <TypographyHeading>Course Lookup</TypographyHeading>
      <Paper sx={{ mt: 2,p:2 }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <CourseLookUpFilter setSearchPayload={setSearchPayload} setOffset={setOffset}  />
        </Box>
        {loading && <Loader />}
        {!loading && stdCourses.length > 0 && !error ? (
          <div>
            <CollapsibleTable
              data={stdCourses}
              table={table}
              id="id"
              handleEdit={handleEdit}
              handleCopy={handleCopy}
              rowsPerPageCount={20}
              paginationSegmentApi="false"
              hasPageApiCall={false}
              showPaginationFooter={false}
            />
          </div>
        ) : (
          <div>
           {!loading && <NoDataFound message="Please Search for Student Course List" />}
          </div>
        )}
        <Box className={classes.BtnWrapper} >
          <Button onClick={()=>{setOffset(offset-10)}} className={classes.Btn} disabled={offset==0}  variant="contained" size="medium">prev</Button>
          <Button onClick={()=>{setOffset(offset+10)}} className={classes.Btn}  disabled={data.length<=9} variant="contained" size="medium">next</Button>
        </Box>
      </Paper>
    </div>
  );
};

