import {useState} from 'react'
import { Grid, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import FieldError from 'shared/organisms/dynamicform/FieldError'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment'

interface IPasswordFieldProps {
  field: any
  control: any
  errors: any
  watch? : any
}

const PasswordField = ({ field, control, errors, watch }: IPasswordFieldProps) => {
  const [show, setShow] = useState(true)
  const showHandler=()=>{
    setShow(!show)
  }
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <TextField
              onChange={onChange}
              value={value}
              label={field.label}
              type={field.visible ? show ? "password" : "text" :  "password"}
              placeholder='Enter Password..'
              required={field.required}
              className={`${field.className}`}
              error={errors && errors[field.id]?.message}
              variant='outlined'
              {...field.extraUiProps}
              sx={{ width: "100%" }}
              size='small'
              InputProps={field.visible && {
                endAdornment: (
                  <InputAdornment position="end" onClick={()=>showHandler()} sx={{cursor:'pointer'}}>
                    {show ? <Visibility/> : <VisibilityOff />}
                  </InputAdornment>
                ),
              }}
            />
            {errors && errors[field.id]?.message && (
              <FieldError field={field} errors={errors} />
            )}
          </>
        )}
      />
    </Grid>
  )
}

export default PasswordField
