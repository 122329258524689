import React, { useState } from "react";
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));
type MultiSelectProps = {
  options: any;
  onChange: any;
  field: any;
  getValues: any;
};
const MultiSelect = ({
  options,
  onChange,
  field,
  getValues,
}: MultiSelectProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event: any, onChange: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (selected.length === options.length) {
        setSelected([]);
        onChange([]);
      } else {
        setSelected(options.map((option: any) => option.value));
        onChange(options);
      }
      return;
    }
    setSelected(value);
    onChange(options.filter((option: any) => value.includes(option.value)));
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel sx={{ background: "white" }} id="mutiple-select-label">
        {field.label}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        renderValue={(selected: any) => selected.join(", ")}
        onChange={(event) => handleChange(event, onChange)}
        variant="outlined"
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {/* {options.map((option: never) => (
          <MenuItem key={option.label} value={option.value}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))} */}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
