import React from "react";
import EditIcon from "@mui/icons-material/EditOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { CustomPopper } from "shared/organisms/popper/custom-popper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LinkIcon from "@mui/icons-material/Link";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import AudioPlayIcon from "assets/audio_play.svg";

type ButtonProps = {
  handleEdit?: () => void;
  handleDelete?: () => void;
  hideDeleteIcon?: boolean;
  enablePreview?: boolean;
  hideEdit?: boolean;
  isDuplicateIcon?: boolean;
  handlePreview?: any;
  handleCopy?: () => void;
  handleDuplicate?: () => void;
  hideCopyIcon?: boolean;
  handlePdf?: any;
  handleVideo?: any;
  viewPdf?: boolean;
  viewSolutionPdf?: boolean;
  viewVideoPdf?: boolean;
  viewPdfUrl?: any;
  viewSolutionPdfUrl?: any;
  viewVideoPdfUrl?: any;
  viewAssignmentPdf?: any;
  viewAssignmentPdfUrl?: any;
  urlLink?: any;
  previewData?: boolean;
  handleQuestionEdit?: () => void;
  showPdf?: boolean;
  viewAudioFile?: boolean;
  viewAudioFileUrl?: any;
};

export const ActionButtons = (props: ButtonProps) => {
  const {
    handleEdit,
    handleDelete,
    hideDeleteIcon,
    hideCopyIcon,
    enablePreview,
    handlePreview,
    handleCopy,
    hideEdit,
    isDuplicateIcon,
    handleDuplicate,
    handlePdf,
    handleVideo,
    viewPdf,
    viewSolutionPdf,
    viewVideoPdf,
    viewPdfUrl,
    viewSolutionPdfUrl,
    viewVideoPdfUrl,
    previewData,
    handleQuestionEdit,
    showPdf,
    viewAudioFile,
    viewAudioFileUrl,
  } = props;
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      {showPdf && handlePdf && typeof handlePdf === "function" && (
        <Tooltip title="pdf preview">
          <DescriptionIcon onClick={handlePdf} />
        </Tooltip>
      )}
      {handleVideo && typeof handleVideo === "function" && (
        <Tooltip title="video preview">
          <YouTubeIcon onClick={handleVideo} />
        </Tooltip>
      )}
      {enablePreview && (
        <Tooltip title="Preview">
          <EyeIcon onClick={handlePreview} />
        </Tooltip>
      )}
      {hideCopyIcon !== true && (
        <Tooltip title="Duplicate">
          <ContentCopyIcon onClick={handleCopy} />
        </Tooltip>
      )}
      {isDuplicateIcon && (
        <Tooltip title="Duplicate">
          <ContentCopyIcon onClick={handleDuplicate} />
        </Tooltip>
      )}
      {hideEdit !== true && !handleQuestionEdit &&(
        <Tooltip title="Edit">
          <EditIcon onClick={handleEdit} />
        </Tooltip>
      )}
      {viewAudioFile && (
        <Tooltip title="Audio Url">
          <img
            src={AudioPlayIcon}
            alt="audio play"
            onClick={viewAudioFileUrl}
          />
        </Tooltip>
      )}
      {viewPdf && (
        <Tooltip title="PDF Url">
          <AssignmentIcon onClick={viewPdfUrl} />
        </Tooltip>
      )}

      {viewSolutionPdf && (
        <Tooltip title="Solution PDF Url">
          <AssignmentTurnedInIcon onClick={viewSolutionPdfUrl} />
        </Tooltip>
      )}

      {viewVideoPdf && (
        <Tooltip title="Solution Video Url">
          <VideoLibraryIcon onClick={viewVideoPdfUrl} />
        </Tooltip>
      )}

      {hideDeleteIcon !== true && (
        <CustomPopper onAgree={handleDelete} sx={{}}>
          <Tooltip title="Delete">
            <DeleteIcon />
          </Tooltip>
        </CustomPopper>
      )}
       {handleQuestionEdit && (
        <Tooltip title="Edit">
          <EditIcon onClick={handleQuestionEdit} />
        </Tooltip>
      )}
      {previewData && (
        <Tooltip title="preview">
          <PreviewOutlinedIcon onClick={handlePreview} />
        </Tooltip>
      )}
    </Stack>
  );
};
