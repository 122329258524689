import { Switch, Route } from "react-router-dom";
import { ForceUpdate } from "./routes/force-update";
import { UpdateVersion } from "./routes/force-update/UpdateVersion";
import { StudentCourseLookUp } from "./routes/student-Course-lookup";
import { CreateStdCourse } from "./routes/student-Course-lookup/CreateStudentCourse";
import { FeatureConfigue } from "./routes/feature-configue";
import { CreateConfig } from "./routes/feature-configue/CreateConfig";
import { StudentProgramLookUp } from "./routes/student-program-lookup";
import { CreateStdProgram } from "./routes/student-program-lookup/CreateStudentProgram";
import { UpdateStdProgram } from "./routes/student-program-lookup/UpdateStudentProgram";
import { UpdateConfig } from "./routes/feature-configue/UpdateConfig";
import { UpdateStdCourse } from "./routes/student-Course-lookup/UpdateStudentCourse";

export function ConfiguePage({ match }: { match: any }) {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}/force_update`}
          component={ForceUpdate}
        />
        <Route
          exact
          path={`${match.url}/force_update/edit/:version_id`}
          component={UpdateVersion}
        />
        <Route
          exact
          path={`${match.url}/student_course_lookup`}
          component={StudentCourseLookUp}
        />
        <Route
          exact
          path={`${match.url}/student_course_lookup/create`}
          component={CreateStdCourse}
        />
        <Route
          exact
          path={`${match.url}/student_course_lookup/duplicate/:course_id`}
          component={UpdateStdCourse}
        />
        <Route
          exact
          path={`${match.url}/student_course_lookup/edit/:course_id`}
          component={UpdateStdCourse}
        />
        <Route
          exact
          path={`${match.url}/student_program_lookup`}
          component={StudentProgramLookUp}
        />
        <Route
          exact
          path={`${match.url}/student_program_lookup/duplicate/:std_program_id`}
          component={UpdateStdProgram}
        />
        <Route
          exact
          path={`${match.url}/student_program_lookup/edit/:std_program_id`}
          component={UpdateStdProgram}
        />
        <Route
          exact
          path={`${match.url}/student_program_lookup/create`}
          component={CreateStdProgram}
        />
        <Route
          exact
          path={`${match.url}/feature_configue`}
          component={FeatureConfigue}
        />
        <Route
          exact
          path={`${match.url}/feature_configue/create`}
          component={CreateConfig}
        />
        <Route
          exact
          path={`${match.url}/feature_configue/edit/:config_id`}
          component={UpdateConfig}
        />
        <Route
          exact
          path={`${match.url}/feature_configue/duplicate/:config_id`}
          component={UpdateConfig}
        />
      </Switch>
    </>
  );
}
