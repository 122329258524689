import useReactHookForm from "hooks/useReactHookForm";
import {
  createStdCourseInitialValues,
  createStdCourseMandatoryFields,
  searchStdCourseMandatoryFields,
  createStdCourseValidationSchema,
  CourseProperties,
  table,
  createStdCoursePropertiesFirst,
  createStdCoursePropertiesSecond,
  tableCreate
} from "./helper";
import { Button, Grid, Paper, Typography } from "@mui/material";
import DynamicForm from "shared/organisms/dynamicform";
import ContentRight from "shared/molecules/content-right";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { useEffect, useState } from "react";
import {
  getBatchFromClassId,
  getCollegeFromStateId,
} from "utils/metadata-split/depedentApiFetch";
import configueService from "pages/configue/service";
import { useHistory } from "react-router-dom";
import { isFieldsValidate } from "shared/organisms/dynamicform/helper";
import { areArraysEqual, setReactFormValues } from "pages/configue/constants";
import { emptyCourseData, StudentCourseLookupRequest, UpdateSingleStdCourseDetails } from "pages/configue/slices/student-course-slice"
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { isEmpty } from "utils/helpers";
import Loader from "pages/build-test/loader";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import NoDataFound from "shared/molecules/no-data";

export const CreateStdCourse = () => {
  const [triggerSetValue, setTriggerSetValue] = useState(false);
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createStdCourseInitialValues,
    createStdCourseValidationSchema,
    ""
  );
  const [courseDetails, setCourseDetails] = useState<any>([]);
  const [isEnabled, setisEnabled] = useState(false);
  const [showSecondaryFields,setShowSecondaryFields] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );
const [course, setCourse] = useState<any>([]);
  const { data, error, loading } = useAppSelector(
    (state) => state.studentCourseLookup
  );

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
    if (metaKey == "batch_list" && listData?.length == 0) {
      setValue("batch_list", []);
    }
    if (metaKey == "college_list" && listData?.length == 0) {
      setValue("college_list", []);
    }
  };

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleCourse = { ...item };
      singleCourse["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleCourse["course_list"] = takeRequiredValue(
        item?.course_list,
        "course_name"
      );
      singleCourse["year_list"] = takeRequiredValue(
        item?.year_list,
        "year_name"
      );
      singleCourse["state_list"] = takeRequiredValue(
        item?.state_list,
        "state_name"
      );
      singleCourse["batch_list"] = takeRequiredValue(
        item?.batch_list,
        "batch_name"
      );
      singleCourse["board_list"] = takeRequiredValue(
        item?.board_list,
        "board_name"
      );
      singleCourse["college_list"] = takeRequiredValue(
        item?.college_list,
        "college_name"
      );
      singleCourse["registration_status_list"] = takeRequiredValue(
        item?.registration_status_list,
        "registration_status_name"
      );
      return singleCourse;
    });
    setCourseDetails(tableData);
  };


  const convertUpdatedDataToTableData = (updateddata) => {
    const tableData = updateddata.map((item, i) => {
      let singleProgram = { ...item };
      singleProgram["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleProgram["program_id"] = takeRequiredValue(
        item?.program_list,
        "program_id"
      );
      singleProgram["program_name"] = takeRequiredValue(
        item?.program_list,
        "program_name"
      );
      return singleProgram;
    });
    setCourseDetails(tableData);
  };

  const handleCreateCourse = async () => {
    const reqBody = {
      id: "id",
      academic_year_list: watch()?.academic_year_list,
      registration_status_list: watch()?.reservation_status_list,
      class_list: watch()?.class_list,
      batch_list: watch()?.batch_list,
      state_list: watch()?.state_list,
      college_list: watch()?.college_list,
      board_list: watch()?.board_list,
      course_id: watch()?.course_id,
      is_secondary_course:
        watch()?.is_secondary_course == "true" ? true : false,
        is_default_course: watch()?.is_default_course == "true" ? true : false,
      is_early_bird_course:
        watch()?.is_early_bird_course == "true" ? true : false,
    };
    const res: any = await configueService.createCourseLookupApi(reqBody);
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(snackbarRequest({ message: "created successfully" }));
      history.replace(`/configue/student_course_lookup`);
    }else{
      dispatch(snackbarRequest({message:res.data.message,type:"error"}));
    }
  };

  const convertArrayToString = (arr) => {
    const params = {};
    let payloadString = "";
    if (typeof arr == "string") {
      return arr;
    } else if (arr?.length > 0) {
      arr.forEach((sateid, i) => {
        if (i == arr.length - 1) {
          payloadString = payloadString + sateid;
        } else {
          payloadString = payloadString + sateid + ",";
        }
      });
      return payloadString;
    }
  };

  const handleSearch = () => {
    setisEnabled(true);
    const payload = {
      class:convertArrayToString(watch()?.class_list),
      academic_year_list:convertArrayToString(watch()?.academic_year_list),
      reservation_status_list:convertArrayToString(watch()?.reservation_status_list),
      // batch_list: watch()?.batch_list,
      limit: 200,
      offset: 0,
      exact_match:true,
      boolean_operation:"AND"
    };
    dispatch(StudentCourseLookupRequest(payload));
  }
  const handleEdit = async (courseId) => {
    const res: any = await configueService.getSingleCourseLookup({
      lookup_id: courseId?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdCourseDetails(res?.data?.data?.[0]));
      history.push(`/configue/student_course_lookup/edit/${courseId?.id}`);
    }
  };

  const handleSubmit = () =>{
    const courseId = watch()?.cousre_id;
    if (courseId) {
      const updatedData = data.filter((item) => item?.course_list?.course_id === courseId);
      convertUpdatedDataToTableData(updatedData);
    } 
  }

  useEffect(() => {
    setTimeout(async () => {
      await getCollegeFromStateId(watch()?.state_list, updateMetaData);
    }, 1000);
  }, [watch()?.state_list]);

  useEffect(() => {
    setTimeout(() => {
      getBatchFromClassId(watch()?.class_list, updateMetaData);
    }, 2000);
  }, [watch()?.class_list]);

  useEffect(() => {
    if (metaData?.batch_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "batch_list",
        watch()?.batch_list,
        "batch_id"
      );
      let isEqual = areArraysEqual(watch()?.batch_list, matchedVales);
      if (!isEqual) {
        setValue("batch_list", matchedVales);
      }
    }
  }, [metaData?.batch_list, triggerSetValue]);

  useEffect(() => {
    if (metaData?.college_list?.length > 0 && !isLoad && triggerSetValue) {
      let matchedVales = setReactFormValues(
        metaData,
        "college_list",
        watch()?.college_list,
        "college_id"
      );
      let isEqual = areArraysEqual(watch()?.college_list, matchedVales);
      if (!isEqual) {
        setValue("college_list", matchedVales);
      }
    }
  }, [metaData?.college_list]);

  useEffect(() => {
    setTimeout(() => {
      setTriggerSetValue(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (data.length > 0 && isEnabled) {
      convertDataToTableData();
    }else{
          setCourseDetails([]);
    }
  }, [data]);

  useEffect(()=>{
    if(watch()?.is_secondary_course=="true"){
      setValue("is_default_course", "false")
      setValue("is_early_bird_course", "false")
    }
  },[watch()?.is_secondary_course])

  useEffect(()=>{
    dispatch(emptyCourseData())
   },[])

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Create New Course
        </Typography>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createStdCoursePropertiesFirst}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Grid>
        <ContentRight sx={{ mt: -7 }}>
          <Button
            variant="contained"
            sx={{
              width: "80px",
              height: "40px",
              marginTop: "15px",
              padding: "7px 42.5px 7px 48.5px",
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={handleSearch}
            disabled={
              !isFieldsValidate(
                errors,
                searchStdCourseMandatoryFields,
                getValues
              )
            }
          >
            Search
          </Button>
        </ContentRight>
        {/* use to get courses based on class_id */}
        {isEnabled && !showSecondaryFields &&
          <div style={{ "marginTop": "15px" }}>
            <ContentRight sx={{ mt: -7 }}>
          <Button
            variant="contained"
            sx={{
              width: "180px",
              height: "40px",
              marginTop: "55px",
              padding: "7px 20px 7px 20px",
              cursor: "pointer",
              textAlign: "center",
              marginBottom: "15px",
            }}
            onClick={()=>{setShowSecondaryFields(true)}}
            disabled={
              !isFieldsValidate(
                errors,
                searchStdCourseMandatoryFields,
                getValues
              )
            }
          >
            Proceed to Create
          </Button>
        </ContentRight>
          </div>
        }
        { showSecondaryFields && <div style={{ "marginTop": "15px" }} >
             <Grid container spacing={2}>
           <DynamicForm
            // fields={CourseProperties(watch())}
             fields={createStdCoursePropertiesSecond(watch())}
             control={control}
             errors={errors}
             getValues={getValues}
             setValue={setValue}
             watch={watch}
           />
         </Grid>
         <ContentRight sx={{ mt: 0 }}>
         <Button
           variant="contained"
           sx={{
             width: "80px",
             height: "40px",
             marginTop: "15px",
             padding: "7px 42.5px 7px 48.5px",
             cursor: "pointer",
             textAlign: "center",
             marginBottom: "15px",
           }}
           onClick={handleCreateCourse}
           disabled={
             !isFieldsValidate(
               errors,
               createStdCourseMandatoryFields,
               getValues
             )
           }
         >
           Create
         </Button>
         </ContentRight>
        </div>
        }
          {loading && <Loader />}
        {!isEmpty(courseDetails) && (
          <CollapsibleTable
          data={courseDetails}
          table={tableCreate}
          id="id"
          handleEdit={handleEdit}
          rowsPerPageCount={10}
          paginationSegmentApi="false"
          hasPageApiCall={false}
        />)}
        
        {!loading && isEmpty(courseDetails)&& isEnabled &&(
          <div>
          <NoDataFound></NoDataFound>
          </div>
        )}
      </Paper>
    </div>
  );
};
