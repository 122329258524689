import NoDataFoundIcon from "assets/images/no-data-found.svg";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
export default function NoDataFound(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 3,
      }}
    >
      <img src={NoDataFoundIcon} alt='nodata'/>
      <Typography>{props.message || "No data found!"}</Typography>
    </Box>
  );
}
