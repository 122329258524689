import React from "react";
import SideBar from "shared/organisms/sidebar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Sidebar2 from 'shared/organisms/sidebar2'
import { useAppSelector } from "hooks/redux";

interface Props {
  children: React.ReactNode;
}

export default function AppLayout(props: Props) {
  const { profileData,isLoading } = useAppSelector((state) => state.gmailLoginReducer);
  const {role:userRoleStr}=profileData
  const userRole = userRoleStr?.split(",");
  return (
    // <Box sx={{ display: "flex" }}>
    //   <SideBar />
    //   <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    //     <Toolbar />
    //     {props.children}
    //   </Box>
    // </Box>
    <div>
  {userRole?.length>0 && !isLoading &&  <Sidebar2>
      {props.children}
    </Sidebar2>}
    </div>
  );
}
