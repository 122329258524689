import { styled } from "@mui/material/styles";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import { makeStyles } from "@mui/styles";
interface StyledListItemButtonProps extends ListItemButtonProps {
  canAccess: boolean;
}
export const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(({ theme,canAccess }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px 18px 10px 14px",
  margin: "0 8px",
  borderRadius: "4px",
  textDecoration: "none",
  color: 'white',
  opacity: 0.8,
  cursor:  canAccess ? 'pointer' : 'not-allowed',
  //pointerEvents: canAccess ? 'auto' : 'none', 
  "&.active": {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    opacity: 1,
    fontWeight: 500,
  },
  "&:hover": {
    opacity: 0.8,
    fontWeight: 500,
  },
}));


export const useStyles = makeStyles({
  listItems:{
    "& .css-10hburv-MuiTypography-root":{
      fontSize:'1rem'
    }
  }
})