export const microServices = {
  NCMSV2: "ncms/v2",
};

export const endpoints = {
  GET_APP_VERSION_LIST: `${microServices.NCMSV2}/app-update/get-app-versions`,
  GET_SINGLE_VERSION_LIST: `${microServices.NCMSV2}/app-update/get-app-version-details`,
  UPDATE_APP_VERSION: `${microServices.NCMSV2}/app-update/update-app-versions`,
  GET_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/get-lookups`,
  GET_SINGLE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/get-lookup-details`,
  CREATE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/create-lookup`,
  UPDATE_COURSE_LOOKUP: `${microServices.NCMSV2}/course-lookup/update-lookup`,
  GET_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/get-lookups`,
  GET_UPDATE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/update-lookup`,
  GET_SINGLE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/get-lookup-details`,
  CREATE_PROGRAM_LOOKUP: `${microServices.NCMSV2}/program-lookup/create-lookup`,
  GET_CONFIG: `${microServices.NCMSV2}/feature-config/get-config`,
  GET_SINGLE_CONFIG: `${microServices.NCMSV2}/feature-config/get-config-details`,
  CREATE_CONFIG: `${microServices.NCMSV2}/feature-config/create-config`,
  UPDATE_CONFIG: `${microServices.NCMSV2}/feature-config/update-config`,
};

// helpers

export const setReactFormValues = (metaData, metaValue, value, keyValue) => {
  let valueArray = metaData?.[`${metaValue}`]?.map((object, i) => {
    return object[`${keyValue}`];
  });

  let matchedValues;
  if (Array.isArray(value)) {
    matchedValues = valueArray.filter((val) => value.includes(val));
  } else {
    matchedValues = valueArray.filter((val) => val === value);
  }

  return matchedValues;
};

export const areArraysEqual = (arr1, array2) => {
  let array1: any = [];
  if (typeof arr1 == "string") {
    array1.push(arr1);
  } else {
    array1 = [...arr1];
  }

  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};

export function hasLengthGreaterThanZero(obj, exceptKey) {
  for (let key in obj) {
    if (key !== exceptKey && obj[key]?.length > 0) {
      return false;
    }
  }
  return true;
}
