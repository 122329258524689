import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import Upload from "./Upload";
interface IUploadFieldProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
  name?: string;
  watch?: any;
  nameValue?: any;
}

const UploadField = ({
  field,
  control,
  errors,
  getValues,
  name,
  watch,
}: IUploadFieldProps) => {
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Upload
            onChange={onChange}
            fields={field}
            getValues={getValues}
            value={value || ""}
            className={field.className}
            watch={watch}
            errors={errors}
          />
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {field.showError ? (
        <FieldError field={field} errors={errors} />
      ) : (
        field.customizableError
      )}
    </Grid>
  );
};

export default UploadField;
