import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { useAppDispatch } from "hooks/redux";

interface IDatePickerFieldProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
}

const DateTimePickerField = ({
  field,
  control,
  errors,
  getValues,
}: IDatePickerFieldProps) => {
  const dispatch: any = useAppDispatch();
  const values: any = getValues();

  const handleChange = (e, onChange) => {
    if (field.minDateTime) {
      const minDateTime = new Date(values[field.minDateTime]).getTime();
      const selectedDateTime = new Date(e).getTime();
      if (minDateTime < selectedDateTime) onChange(e);
      else
        dispatch(
          snackbarRequest({
            type: "error",
            message: `Selected time should be greater than ${field.minDateTime}`,
          })
        );
    } else {
      onChange(e);
    }
  };

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  error={errors && errors[field.id]?.message}
                  required={field.required}
                  size="small"
                  defaultValue={""}
                />
              )}
              inputFormat="dd-MM-yyyy HH:mm"
              label={field.label}
              value={value || ""}
              onChange={(e) => handleChange(e, onChange)}
              minDateTime={values[field.minDateTime] || undefined}
              maxDateTime={values[field.maxDateTime] || undefined}
              disabled={field.disabled}
            />
          </LocalizationProvider>
        )}
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default DateTimePickerField;
