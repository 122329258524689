import { Box, Button, Grid, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Loader from "pages/build-test/loader";
import { Link, useHistory } from "react-router-dom";
import ContentRight from "shared/molecules/content-right";
import NoDataFound from "shared/molecules/no-data";
import { TypographyHeading } from "shared/molecules/typography-layout";
import CollapsibleTable from "shared/organisms/tables/collapsible-table";
import { makeStyles } from "@mui/styles";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { StudentCourseLookupRequest } from "pages/configue/slices/student-course-slice";
import {
  StudentProgramLookupRequest,
  UpdateSingleStdProgramDetails,
} from "pages/configue/slices/student-program-slice";
import { number } from "yup";
import SectionTable from "shared/organisms/tables/table";
import { table } from "./helper";
import configueService from "pages/configue/service";
import { ProgramLookUpFilter } from "./programLookup-filter/ProgramLookUpFilter";
import { isEmpty } from "utils/helpers";

const useStyles: any = makeStyles((indigoTheme) => ({
  label: {
    textDecoration: "none",
    textAlign: "center",
    color: indigo[700],
    marginLeft: "80%",
  },
  Btn:{
    backgroundColor:"#3f50b5",
    color:"white",
    width:"90px"
},
BtnWrapper:{
    display: "flex",
    gap: "20px",
    justifyContent:"flex-end",
    paddingTop:"10px",
    paddingBottom:"10px",
    paddingRight:"30px"
}
}));

export const StudentProgramLookUp = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { data, error, loading } = useAppSelector(
    (state) => state.studentProgram
  );
  const [programDetails, setProgramDetails] = useState<any>([]);
  const[limit,setLimit]=useState(10);
  const[offset,setOffset]=useState(0);
  const[searchPayload,setSearchPayload]=useState({})

  const takeRequiredValue = (classList, key) => {
    const classNameList = classList?.map((SingleClass) => {
      return SingleClass[`${key}`];
    });
    return classNameList;
  };

  const convertDataToTableData = () => {
    const tableData = data.map((item, i) => {
      let singleProgram = { ...item };
      singleProgram["class_list"] = takeRequiredValue(
        item?.class_list,
        "class_name"
      );
      singleProgram["program_id"] = takeRequiredValue(
        item?.program_list,
        "program_id"
      );
      singleProgram["program_name"] = takeRequiredValue(
        item?.program_list,
        "program_name"
      );
      return singleProgram;
    });
    setProgramDetails(tableData);
  };

  const handleEdit = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id?.id,
      id: std_program_id?.id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(`/configue/student_program_lookup/edit/${std_program_id?.id}`);
    }
  };

  const handleCopy = async (std_program_id: any) => {
    const res: any = await configueService.getSingleProgramLookupListApi({
      lookup_id: std_program_id,
      id: std_program_id,
    });
    if (res?.status === 200 && res?.data?.status === "success") {
      dispatch(UpdateSingleStdProgramDetails(res?.data?.data?.[0]));
      history.push(
        `/configue/student_program_lookup/duplicate/${std_program_id}`
      );
    }
  };

  useEffect(() => {
    dispatch(StudentProgramLookupRequest({...searchPayload,limit,offset}));
  }, [offset,searchPayload]);

  useEffect(() => {
    if (data.length > 0) {
      // covert data intotable formate
      convertDataToTableData();
    }else if(isEmpty(data)){
      setProgramDetails([])
    }
  }, [data]);

  return (
    <div>
      <ContentRight>
        <Link
          to="/configue/student_program_lookup/create"
          className={classes.label}
        >
          <Button variant="contained">Create New Program Lookup</Button>
        </Link>
      </ContentRight>
      <TypographyHeading>Program Lookup</TypographyHeading>
      <Paper sx={{ mt: 2, p: 2 }}> 
        <Box sx={{ mt: 2, mb: 2 }}>
          <ProgramLookUpFilter setSearchPayload={setSearchPayload} setOffset={setOffset}  />
        </Box>
        {loading && <Loader />}
        {!loading && programDetails.length > 0 && !error ? (
          // <SectionTable
          //   data={programDetails}
          //   table={table}
          //   id="id"
          //   name="version_id"
          //   handleEdit={handleEdit}
          //   handleCopy={handleCopy}
          //   rowsPerPageCount={10}
          //   pagination="false"
          //   style="false"
          //   totlaRecords={0}
          //   // hasPageApiCall={false}
          //   paginationSegment="false"
          //   viewSecondaryData="false"
          // />
          <CollapsibleTable
          data={programDetails}
          table={table}
          id="id"
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          rowsPerPageCount={20}
          paginationSegmentApi="false"
          hasPageApiCall={false}
          showPaginationFooter={false}
        />
        ) : (
          <div>
         { !loading &&  <NoDataFound message="No student program List found, Please create new List" />}
          </div>
        )}

        <Box className={classes.BtnWrapper} >
          <Button onClick={()=>{setOffset(offset-10)}} className={classes.Btn} disabled={offset==0}  variant="contained" size="medium">prev</Button>
          <Button onClick={()=>{setOffset(offset+10)}} className={classes.Btn}  disabled={data.length<=9}  variant="contained" size="medium">next</Button>
        </Box>
      </Paper>
    </div>
  );
};

//   <CollapsibleTable
//     data={data}
//    // table={table}
//     id="delivery_id"
//     // handleEdit={handleEdit}
//     // handleCopy={handleCopy}
//     rowsPerPageCount={20}
//     paginationSegmentApi="false"
//     hasPageApiCall={false}
//   />
