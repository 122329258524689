import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";

interface ISingleSelectCustomFieldProps {
  field: any;
  control: any;
  errors: any;
  filteredData: any;
  getValues?: any;
}

const SingleSelectCustom = ({
  field,
  errors,
  control,
  filteredData,
  getValues,
}: ISingleSelectCustomFieldProps) => {
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <FormControl error={errors && errors[field.id]?.message}>
              <InputLabel id="demo-simple-select-label">
                {field.label}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || ""}
                label={field.label}
                className={field.className}
                sx={{ minWidth: "251px" }}
                required={field.required}
                onChange={onChange}
                MenuProps={{
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    },
                  },
                }}
              >
                {filteredData.map((option: any) => {
                  return (
                    <MenuItem
                      value={JSON.stringify({
                        label: option.label,
                        value: option.value,
                      })}
                      key={option.label}
                    >
                      {option.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default SingleSelectCustom;
