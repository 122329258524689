import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "hooks/redux";

interface IMultiSelctProps {
  field: any;
  control: any;
  errors: any;
  getValues?: any;
  setValue: any;
  watch?: any;
  name?: any;
  indexNumber?: any;
}

const useStyles = makeStyles({
  border: {
    "& .css-m1opko-MuiFormLabel-root-MuiInputLabel-root": {
      background: "white",
      paddingRight: "5px",
      paddingLeft: "5px",
    },
  },
});

const MultiSelect = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
  name,
  indexNumber,
}: IMultiSelctProps) => {
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event: any, onChange: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (selected.length === options.length) {
        setSelected([]);
        onChange([]);
      } else {
        const filteredArr = options.map((option: any) => option.value);
        setSelected(filteredArr);
        onChange(filteredArr);
      }
      if (field.dependentFields)
        field.dependentFields.map((item: string) => setValue(`${item}`, ""));
      return;
    }
    setSelected(value);
    onChange(value);
    if (field.dependentFields)
      field.dependentFields.map((item: string) => setValue(`${item}`, ""));
  };

  const handleBlur = (event: any, onBlur: any) => {
    console.log("hi world");
  };

  useEffect(() => {
    if (metaData) {
      const jsonMetaData = metaData;
      const temp = jsonMetaData[field.list];
      let opts: any = [];
      if (temp && temp.length > 0) {
        opts = temp?.map((item: any) => ({
          label: item[field.keyValue.label],
          value: item[field.keyValue.value],
        }));
        setOptions(opts);
      }
    }
  }, [field, updatedAt]);

  const hasOptions = options && options.length > 0;
  const classes = useStyles();

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <Box>
            <FormControl
              style={{ width: "100%" }}
              size="small"
              className={classes.border}
            >
              <InputLabel
                sx={{
                  color:
                    errors && (singleFormError || multiFormError)
                      ? "#E83845"
                      : "",
                  background: "white",
                }}
                // sx={{background: "white"}}
                id="demo-multiple-checkbox-label"
              >
                {field.label}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={value || []}
                inputProps={{
                  readOnly: field.readOnly ? field.readOnly : false,
                }}
                required={field.required}
                multiple
                error={multiFormError ? multiFormError : singleFormError}
                onChange={(event) => handleChange(event, onChange)}
                onBlur={(event) => handleBlur(event, onBlur)}
                renderValue={(selected: any) => {
                  const names = options.reduce((acc: string[], item: any) => {
                    if (selected.includes(item.value))
                      return (acc = [...acc, item.label]);
                    return acc;
                  }, []);
                  const namesLenght: any =
                    names.length > 1 ? `+${names.length - 1}` : "";
                  return names
                    .join(", ")
                    .replace(/(.{15})..+/, `$1...${namesLenght}`);
                }}
                MenuProps={{
                  autoFocus: true,
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "auto",
                      // margin: "10px",
                    },
                    "&& .MuiMenuItem-root": {
                      // padding: "8px",
                      width: "100%",
                    },
                  },
                }}
                name={field.id}
              >
                {hasOptions && (
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: "" }}
                        checked={value.length === options.length}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: "" }}
                      primary="Select All"
                    />
                  </MenuItem>
                )}
                {hasOptions &&
                  options.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          value ? value.indexOf(option.value) > -1 : false
                        }
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </Box>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {/* {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )} */}

      {errors && (singleFormError || multiFormError) && (
        <FieldError
          field={field}
          errors={errors}
          name={name}
          indexNumber={indexNumber}
        />
      )}
    </Grid>
  );
};

export default MultiSelect;
