import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import { useStyles } from "./styles";

import TableMainBody from "./table-body";
import TableTtiles from "./table-titles";
import { TablePaginationActions } from "./table-pagination-action";

export default function CollapsibleTable({
  data,
  handleEdit,
  handleDelete,
  handleCopy,
  id,
  name,
  table,
  handleStore,
  handleDeliver,
  handleAnswer,
  handlePreview,
  rowsPerPageCount,
  addUrlNavigation,
  viewPdf,
  viewSolutionPdf,
  viewVideoPdf,
  viewPdfUrl,
  viewSolutionPdfUrl,
  viewVideoPdfUrl,
  titleBarStyle,
  hasPageApiCall,
  reqBody,
  pageLimit,
  paginationSegmentApi,
  totlaRecords,
  previewData,
  style,
  handlePdf,
  showPdf,
  viewAudioFile,
  viewAudioFileUrl,
  showPaginationFooter=true
}: any) {
  const styles = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt(rowsPerPageCount) || 10
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (hasPageApiCall != false) {
      const pages = { page_no: newPage + 1, page_limit: pageLimit };
      const reqObj = { ...reqBody, ...pages };
      (await hasPageApiCall) != false && hasPageApiCall(reqObj);
      setPage(newPage);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (hasPageApiCall != false) {
      // setPage(0)
      const pages = {
        page_no: 1,
        page_limit:
          parseInt(event.target.value) == -1
            ? totlaRecords && totlaRecords > 0
              ? totlaRecords
              : data.length
            : event.target.value,
      };
      const reqObj = { ...reqBody, ...pages };
      (await hasPageApiCall) != false && hasPageApiCall(reqObj);
      // setPage(page);
    }
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead className={style}>
          <TableRow className={styles.mainTableHeading} sx={titleBarStyle}>
            <TableTtiles data={table.primaryData} />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paginationSegmentApi == "true" &&
            rowsPerPage > 0 &&
            data
              .slice(0, rowsPerPageCount)
              .map((row: any, index: any) => (
                <TableMainBody
                  key={`${id}-${index}`}
                  row={row}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  id={id}
                  name={name}
                  table={table}
                  handleStore={handleStore}
                  handleDeliver={handleDeliver}
                  handleAnswer={handleAnswer}
                  handlePreview={handlePreview}
                  handleCopy={handleCopy}
                  addUrlNavigation={addUrlNavigation}
                  viewPdf={viewPdf}
                  viewSolutionPdf={viewSolutionPdf}
                  viewVideoPdf={viewVideoPdf}
                  viewPdfUrl={viewPdfUrl}
                  viewSolutionPdfUrl={viewSolutionPdfUrl}
                  viewVideoPdfUrl={viewVideoPdfUrl}
                  previewData={previewData}
                  handlePdf={handlePdf}
                  showPdf={showPdf}
                  viewAudioFile={viewAudioFile}
                  viewAudioFileUrl={viewAudioFileUrl}
                />
              ))}

          {!(rowsPerPage > 0) &&
            data.map((row: any, index: any) => (
              <TableMainBody
                key={`${id}-${index}`}
                row={row}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                id={id}
                name={name}
                table={table}
                handleStore={handleStore}
                handleDeliver={handleDeliver}
                handleAnswer={handleAnswer}
                handlePreview={handlePreview}
                handleCopy={handleCopy}
                addUrlNavigation={addUrlNavigation}
                viewPdf={viewPdf}
                viewSolutionPdf={viewSolutionPdf}
                viewVideoPdf={viewVideoPdf}
                viewPdfUrl={viewPdfUrl}
                viewSolutionPdfUrl={viewSolutionPdfUrl}
                viewVideoPdfUrl={viewVideoPdfUrl}
                previewData={previewData}
                handlePdf={handlePdf}
                showPdf={showPdf}
                viewAudioFile={viewAudioFile}
                viewAudioFileUrl={viewAudioFileUrl}
              />
            ))}

          {paginationSegmentApi == "false" &&
            rowsPerPage > 0 &&
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => (
                <TableMainBody
                  key={`${id}-${index}`}
                  row={row}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  id={id}
                  name={name}
                  table={table}
                  handleStore={handleStore}
                  handleDeliver={handleDeliver}
                  handleAnswer={handleAnswer}
                  handlePreview={handlePreview}
                  handleCopy={handleCopy}
                  addUrlNavigation={addUrlNavigation}
                  viewPdf={viewPdf}
                  viewSolutionPdf={viewSolutionPdf}
                  viewVideoPdf={viewVideoPdf}
                  viewPdfUrl={viewPdfUrl}
                  viewSolutionPdfUrl={viewSolutionPdfUrl}
                  viewVideoPdfUrl={viewVideoPdfUrl}
                  previewData={previewData}
                  handlePdf={handlePdf}
                  showPdf={showPdf}
                  viewAudioFile={viewAudioFile}
                  viewAudioFileUrl={viewAudioFileUrl}
                />
              ))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={12} />
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
       {showPaginationFooter && 
            <TablePagination
              rowsPerPageOptions={
                paginationSegmentApi == "true"
                  ? [rowsPerPageCount, { label: "All", value: -1 }]
                  : [
                      rowsPerPageCount,
                      rowsPerPageCount + 5,
                      rowsPerPageCount + 10,
                      rowsPerPageCount + 15,
                      { label: "All", value: -1 },
                    ]
              }
              colSpan={12}
              count={
                totlaRecords && totlaRecords > 0 ? totlaRecords : data.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
