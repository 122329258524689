import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import EventIcon from "@mui/icons-material/Event";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import SearchIcon from "@mui/material/icons/Search";
import { TextInput } from "./styles";

interface IDatePickerFieldProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
  watch: any;
}

const DatePickerField = ({
  field,
  control,
  errors,
  getValues,
  watch,
}: IDatePickerFieldProps) => {
  const [open, setOpen] = React.useState(false);

  const values: any = getValues();
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              inputFormat="dd-MM-yyyy"
              label={field.label}
              open={field.readOnly ? false : open}
              value={value || ""}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onChange={onChange}
              disabled={field.disabled}
              minDate={
                field.id === "end_date" ? values.start_date : field.minDate
              }
              maxDate={
                field.id === "start_date" ? values.end_date : field.maxDate
              }
              renderInput={(params) => (
                <TextInput
                  $readOnly={field.readOnly}
                  sx={{ width: "100%" }}
                  {...params}
                  defaultValue={""}
                  error={errors && errors[field.id]?.message}
                  required={field.required}
                  size="small"
                  // inputProps={{
                  //   readOnly: field.readOnly,
                  // }}
                  //InputProps={field.image}
                />
              )}
            />
          </LocalizationProvider>
        )}
        name={field.id}
        control={control}
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default DatePickerField;
