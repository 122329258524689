import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Checkbox as SelectCheckbox, Grid } from "@mui/material";
import { genearteLabelAndValue } from "./helper";
import { metaData } from "./helper";
import UseMultiSelect from "shared/molecules/fields/multi-select/MultiSelect";
import UploadFieldAndDownload from "shared/molecules/fields/upload-download";
import {
  TextFieldComponent,
  EmailField,
  NumberField,
  PasswordField,
  AddressField,
  RadioField,
  NormalSelect,
  SingleSelectDependency,
  SingleSelectWithDownload,
  UrlField,
  DatePickerField,
  DateTimePickerField,
  UploadField,
  SingleSelectCustom,
  MultiSelect,
  MultiSelectDependency,
  MultiTextComponent,
  PlaceHolderTextField,
  NewDatePicker,
  MultiUpload,
  MultiImageUpload,
} from "shared/molecules/fields";
import TextAreaField from "shared/molecules/fields/text-area";
import UrlFileUploadField from "shared/molecules/fields/url-file-upload";

type FormProps = {
  errors: any;
  control: any;
  getValues: (id: string) => void;
  reset?: (data: {}) => void;
  fields: any;
  setValue?: any;
  watch?: any;
  register?: any;
  index?: any;
  name?: string;
  indexNumber?: number;
};

export default function DynamicForm(props: FormProps) {
  const {
    fields,
    control,
    errors,
    getValues,
    setValue,
    watch,
    name,
    indexNumber,
  } = props;
  const [selectedData, setSelectedData] = useState<string>("");
  const [filteredData, setFilteredData] = useState<{}[]>([]);

  useEffect(() => {
    if (selectedData) {
      let parsedSelectedData = JSON.parse(selectedData);
      let filtered = metaData.state_list.filter(
        (state) => state.state_name === parsedSelectedData.value
      );
      let filteredData = filtered.flatMap((data: any) => {
        return genearteLabelAndValue(data.city_list);
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(
        metaData.state_list.flatMap((data: any) =>
          genearteLabelAndValue(data["city_list"])
        )
      );
    }
  }, [selectedData]);

  const renderField = (field: any, index: number) => {
    switch (field.type) {
      //text
      case "text":
        return (
          <TextFieldComponent
            field={field}
            control={control}
            errors={errors}
            indexNumber={indexNumber}
            name={name}
          />
        );

      case "text-area":
        return (
          <TextAreaField
            field={field}
            control={control}
            errors={errors}
            // watch={watch}
            // indexNumber={indexNumber}
            // name={name}
          />
        );
      //auto place holder text field
      case "placeholder-text":
        return (
          <PlaceHolderTextField
            field={field}
            control={control}
            errors={errors}
            watch={watch}
          />
        );
      //number
      case "number":
        return (
          <NumberField
            field={field}
            control={control}
            errors={errors}
            name={name}
            indexNumber={indexNumber}
            setValue={setValue}
          />
        );

      // password
      case "password":
        return (
          <PasswordField
            field={field}
            control={control}
            errors={errors}
            watch={watch}
          />
        );
      //email
      case "email":
        return <EmailField field={field} control={control} errors={errors} />;

      case "address":
        return <AddressField field={field} control={control} errors={errors} />;

      case "radio":
        return <RadioField field={field} control={control} errors={errors} />;

      case "single-select-custom":
        return (
          <SingleSelectCustom
            field={field}
            errors={errors}
            control={control}
            filteredData={filteredData}
            getValues={getValues}
          />
        );

      case "normal-select":
        return (
          <NormalSelect
            getValues={getValues}
            field={field}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch()}
          />
        );

      case "multi-select":
        return (
          <MultiSelect
            getValues={getValues}
            field={field}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            name={name}
            indexNumber={indexNumber}
          />
        );

      case "multi-select-all":
        return (
          <Grid item {...field.breakpoints}>
            {" "}
            <Controller
              render={({ field: { onChange, value } }) => (
                <UseMultiSelect
                  getValues={getValues}
                  options={field.options}
                  onChange={onChange}
                  field={field}
                />
              )}
              name="examsall"
              control={control}
              defaultValue=""
            />
          </Grid>
        );

      case "upload":
        return (
          <UploadField
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            name={name}
            indexNumber={indexNumber}
            watch={watch()}
          />
        );

      case "upload-download":
        return (
          <UploadFieldAndDownload
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            name={name}
            indexNumber={indexNumber}
            watch={watch()}
          />
        );

      case "multi-upload":
        return (
          <MultiUpload
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            name={name}
            watch={watch()}
          />
        );
      case "multi-image-upload":
        return (
          <MultiImageUpload
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            name={name}
            watch={watch()}
          />
        );

      case "url":
        return <UrlField field={field} control={control} errors={errors} />;
      case "url-file-upload":
        return (
          <UrlFileUploadField
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            nameValue={name}
            indexNumber={indexNumber}
          />
        );

      case "single-select-dependency":
        return (
          <SingleSelectDependency
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            setSelectedData={setSelectedData}
            setValue={setValue}
            watch={watch()}
            indexNumber={indexNumber}
            name={name}
          />
        );
      case "single-select-with-download":
        return (
          <SingleSelectWithDownload
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            setSelectedData={setSelectedData}
            setValue={setValue}
            watch={watch()}
          />
        );
      case "multi-select-dependency":
        return (
          <MultiSelectDependency
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch()}
            indexNumber={indexNumber}
            name={name}
          />
        );
      case "date-picker":
        return (
          <DatePickerField
            getValues={getValues}
            field={field}
            control={control}
            errors={errors}
            watch={watch()}
          />
        );
      case "new-date-picker":
        return (
          <NewDatePicker
            getValues={getValues}
            field={field}
            control={control}
            errors={errors}
            watch={watch()}
          />
        );
      case "date-time-picker":
        return (
          <DateTimePickerField
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
          />
        );
      case "multi-text":
        return (
          <MultiTextComponent
            field={field}
            control={control}
            errors={errors}
            getValues={getValues}
            watch={watch()}
          />
        );
      default:
        return null;
    }
  };
  return <React.Fragment>{fields && fields.map(renderField)}</React.Fragment>;
}
