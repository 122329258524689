import { useEffect, useState, SyntheticEvent } from "react";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { Controller } from "react-hook-form";
import { ISelectFieldProps } from "./types";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { StyledSelect } from "./styles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import request from "utils/axios/service";
import { metadataSuccess } from "app-component/slices/metadata-slice";

const NormalSelect = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
}: ISelectFieldProps) => {
  const [options, setOptions] = useState([]);
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );
  const dispatch = useAppDispatch();

  const convertToLabelValueFormat = (field: any, data) => {
    if (!data || data.length === 0) return [];
    return data.map((item: any) => ({
      label: item[field.keyValue.label],
      value: item[field.keyValue.value],
    }));
  };

  const getDataFromApi = async (cb) => {
    try {
      const res: any = await request.get(field.endpoint, {});
      if (res && res.status === 200 && res.data.status === "success") {
        const dummyRes = await cb(res.data.data);
        return convertToLabelValueFormat(field, res?.data?.data);
        
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (field?.options?.length > 0) {
      setOptions(field.options);
      return;
     }
     //else if (field.onlyApiCall) {
    //   const updateMetaData=(listData)=>{
    //     let metadataList = {...metaData};
    //     metadataList[field.list] = listData;
    //     // dispatch(metadataSuccess(metadataList))
    //   }
    //   getDataFromApi(updateMetaData).then((data) => {
    //     setOptions(data);
    //   });
    // }
    if (metaData) {
      const jsonMetaData = metaData;
      const temp = jsonMetaData[field.list];
      let opts: any = [];
      if (temp && temp.length > 0 && !field.onlyApiCall) {
        opts = temp.map((item: any) => ({
          label: item[field.keyValue.label],
          value: item[field.keyValue.value],
        }));
        setOptions(opts);
      }
    }
  }, [field, updatedAt]);

  useEffect(()=>{
    if (field.onlyApiCall) {
      const updateMetaData=(listData)=>{
        let metadataList = {...metaData};
        metadataList[field.list] = listData;
        dispatch(metadataSuccess(metadataList))
      }
      getDataFromApi(updateMetaData).then((data) => {

        setOptions(data);
      });
    }
  },[])

  const handleChange = (e: any, onChange) => {
    onChange(e.target.value);
  };

  const hasOptions = options && options.length > 0;
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <FormControl
              sx={{ width: "100%" }}
              error={errors && errors[field.id]?.message}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">
                {field.label}
              </InputLabel>
              <StyledSelect
                $readOnly={field.readOnly}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || ""}
                label={field.label}
                className={field.className}
                required={field.required}
                disabled={field.disabled}
                // input={<DoNotDisturbIcon name="age" id="age-customized-select" />}
                inputProps={{
                  readOnly: field.readOnly ? field.readOnly : false,
                }}
                onChange={(e) => handleChange(e, onChange)}
                MenuProps={{
                  sx: {
                    "&& .MuiList-root": {
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    },
                  },
                }}
              >
                {!hasOptions && <MenuItem disabled>No options found</MenuItem>}
                {hasOptions&& !field?.hideNone && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                {hasOptions &&
                  options.map((option: any) => {
                    return (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
            </FormControl>

            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default NormalSelect;
