import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
  updateStudentCorseDetails: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  successMsg: {},
  updateStudentCorseDetails: {},
};

const studentCourseSlice = createSlice({
  name: "studentCourse",
  initialState,
  reducers: {
    StudentCourseLookupRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      //state.filtersData = action.payload;
    },
    StudentCourseLookupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    UpdateSingleStdCourseDetails(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.updateStudentCorseDetails = action.payload;
    },
    emptyCourseData(state: IInitState) {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  StudentCourseLookupRequest,
  StudentCourseLookupSuccess,
  UpdateSingleStdCourseDetails,
  emptyCourseData,
} = studentCourseSlice.actions;
export default studentCourseSlice.reducer;
