import * as React from "react";
import { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";
import { display } from "@mui/system";
import { isCreateTemplateDisabled } from "pages/build-test/routes/template/template-creation/helpers";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));
export default function Eclipse(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [length, setLength] = React.useState(0);
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const containerRef = React.useRef();

  React.useEffect(() => {
    if (props.displayString && !Array.isArray(props.item)) setLength(0);
    else setLength(props.item?.split(",").map((item: any) => item).length - 1);
  }, [props.item]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    !props.disablePop && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popoverEnter = async ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  const truncateAfterThreeWords = (text) => {
    const parts = text.split(',');
    const truncated = parts.slice(0, 6).join(', ');
    const remainingWords = parts.length - 3;
    return truncated + (remainingWords > 0 ? `...+${remainingWords}` : '');
  };

  const splitTextAtCommas = (text) => {
    return text.split(',').map((part, index) => (
      <span key={index}>
        {part}{index < text.split(',').length - 1 ? ',' : ''}
        <br />
      </span>
    ));
  };
  const processedText = truncateAfterThreeWords(props?.item?.toString());
  return (
    <>
      <Box
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        sx={{
          cursor: "pointer",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {/* {props.item} */}
        {!props.displayFullstring &&
          !props.displayCharacterCount &&
          !props.multiLine && !props.isConfigueType && 
          props?.item
            ?.toString()
            .replace(/(.{10})..+/, `$1...${length > 0 ? "+" + length : ""}`)}

          {props.isConfigueType && <p style={{  whiteSpace: 'normal',  wordWrap: 'break-word',}} >{!props.displayFullstring &&
          !props.displayCharacterCount &&
          !props.multiLine &&
          splitTextAtCommas(
            //props?.item?.toString().replace(/(.{50})..+/, `$1...${length > 0 ? "+" + length : ""}`)
           // processedText
           props?.item
          )}</p>}

        {!props.displayFullstring &&
          props.displayCharacterCount &&
          !props.multiLine &&
          props?.item
            ?.toString()
            .replace(/(.{20})..+/, `$1...${length > 0 ? "+" + length : ""}`)}
        {!props.displayFullstring &&
          !props.displayCharacterCount &&
          props.multiLine &&
          props?.item.split("\n").length > 0 &&
          props?.item
            .split("\n")[0]
            ?.toString()
            .replace(/(.{11})..+/, `$1...${length > 0 ? "+" + length : ""}`)}
        {props.displayFullstring && props.item}
      </Box>
      <Box ref={containerRef}>
        <Popover
          disableScrollLock={true}
          disableRestoreFocus={true}
          container={containerRef.current}
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.popoverContent,
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
          sx={{ cursor: "text" }}
        >
          <List>
            {props.displayString && !Array.isArray(props.item) ? (
              <ListItem key={props.item}>{props.item}</ListItem>
            ) : props.multiLine ? (
              <ListItem>{props.item}</ListItem>
            ) : (
              props.item
                ?.split(",")
                .map((item: any) => <ListItem key={item}>{item}</ListItem>)
            )}
          </List>

          {/* <List>
            {props.item.split(",").map((item: any) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List> */}
        </Popover>
      </Box>
    </>
  );
}
