import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import './style.css'
import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

interface IDatePickerFieldProps {
  field: any;
  control: any;
  errors: any;
  getValues: any;
  watch: any;
}

const DatePickerField = ({
  field,
  control,
  errors,
  getValues,
  watch,
}: IDatePickerFieldProps) => {
  let inputRef:any=React.useRef<any>(null)
  const values: any = getValues();
  const handleClick=()=>{
    console.log("click", inputRef.current.onclick)
  }
  return (
    <Grid item {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label={field.label}
              value={value || ""}
              readOnly={false}
              onChange={onChange}
              minDate={
                field.id === "end_date" ? values.start_date : field.minDate
              }
              maxDate={
                field.id === "start_date" ? values.end_date : field.maxDate
              }
              renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%",zIndex:1 }}
                    {...params}
                    defaultValue={""}
                    error={errors && errors[field.id]?.message}
                    required={field.required}
                    className="custom-date-picker"
                    size="small"
                  />
              )}
            />
          </LocalizationProvider>
        )}
        name={field.id}
        control={control}
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default DatePickerField;
