import { Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import FieldError from "shared/organisms/dynamicform/FieldError";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useState } from "react";
import { useAppDispatch } from "hooks/redux";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import URLCopy from "assets/url-copy.svg";
import { TextInput } from "./styles";

interface ITextFieldProps {
  field: any;
  control: any;
  errors: any;
  name?: any;
  indexNumber?: any;
}

const TextFieldComponent = ({
  field,
  control,
  errors,
  name,
  indexNumber,
}: ITextFieldProps) => {
  const dispatch = useAppDispatch();
  const multiFormError =
    errors && errors?.[name]?.[indexNumber]?.[field.id]?.message;
  const singleFormError = errors && errors[field.id]?.message;

  const copyContent = async (value) => {
    try {
      if (value) {
        await navigator.clipboard.writeText(value);
        dispatch(snackbarRequest({ message: "copied successfully" }));
      } else {
        dispatch(
          snackbarRequest({ message: "no data to copy", type: "warning" })
        );
      }
    } catch (err) {
      dispatch(snackbarRequest({ message: "failed to copy", type: "error" }));
    }
  };

  return (
    <Grid item {...field.breakpoints}>
      <Controller
        name={name ? `${name}.${indexNumber}.${field.id}` : field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <TextInput
              $readOnly={field.readOnly}
              onChange={onChange}
              value={value || ""}
              label={field.label}
              type="text"
              placeholder={field.placeholder ? field.placeholder : field.label}
              className={field.className}
              fullWidth
              error={multiFormError ? multiFormError : singleFormError}
              variant="outlined"
              required={field.required}
              sx={{ width: "100%" }}
              style={field.style}
              size="small"
              disabled={field.disabled}
              inputProps={{
                readOnly: field.readOnly ? field.readOnly : false,
              }}
              InputProps={
                field.icon && {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ cursor: "pointer", height: "40px" }}
                    >
                      <div
                        style={{
                          borderLeft: "1px solid #ced4da",
                          height: "40px",
                        }}
                      >
                        <Tooltip title="copy">
                          <img
                            src={URLCopy}
                            onClick={() => copyContent(value)}
                            style={{ marginTop: "8px", marginLeft: "10px" }}
                          />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }
              }
            />
            {errors && (singleFormError || multiFormError) && (
              <FieldError
                field={field}
                errors={errors}
                name={name}
                indexNumber={indexNumber}
              />
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default TextFieldComponent;
