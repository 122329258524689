import * as Yup from "yup";
export type createConfigType = {
  academic_year_list: string[]; //
  reservation_status_list: string[]; //
  class_list: string[]; //
  batch_list: string[]; //
  sub_batch_list: string[]; //
  platform_list: string[];
  college_list: string[]; //
};

export const createConfigInitialValues: createConfigType = {
  academic_year_list: [],
  reservation_status_list: [],
  class_list: [],
  batch_list: [],
  sub_batch_list: [],
  platform_list: [],
  college_list: [],
};

export const createConfigMandatoryFields = [
  { id: "academic_year_list" },
  { id: "reservation_status_list" },
  { id: "class_list" },
  { id: "batch_list" },
  { id: "sub_batch_list" },
  { id: "platform_list" },
  { id: "college_list" },
  ,
];

export const createConfigValidationSchema = Yup.object().shape({
  academic_year_list: Yup.array()
    .required("value required")
    .min(1, "value required"),
  reservation_status_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
  // reservation_status_id:Yup.array().of(Yup.string()).required("value required"),
  class_list: Yup.array().min(1, "value required").required("value required"),
  batch_list: Yup.array().min(1, "value required").required("value required"),
  sub_batch_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
  college_list: Yup.array().min(1, "value required").required("value required"),
  platform_list: Yup.array()
    .min(1, "value required")
    .required("value required"),
});

export const createConfigProperties = [
  {
    id: "academic_year_list",
    type: "multi-select",
    label: "Academic Year",
    className: "select-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
  },
  {
    id: "reservation_status_list",
    type: "multi-select",
    label: "Registration Status",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    options: [],
    list: "reservation_status_list",
    keyValue: {
      label: "registration_status_name",
      value: "registration_status_id",
    },
  },
  {
    id: "class_list",
    type: "multi-select-dependency",
    label: "Class",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 1.9 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["batch_list", "sub_batch_list"],
    dependentFlds: {
      class_course_batch_data: "class_id",
    },
  },
  {
    id: "batch_list",
    type: "multi-select-dependency",
    label: "Batch",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 1.9 },
    isDependent: true,
    options: [],
    required: true,
    list: "batch_list",
    dependencyLabel: "Please select Class",
    keyValue: { label: "batch_name", value: "batch_id" },
    fieldKeyLists: {},
    dependentFields: ["sub_batch_list"],
    dependentFlds: {
      batch_list: "batch_id",
    },
  },
  {
    id: "sub_batch_list",
    type: "multi-select-dependency",
    label: "Sub Batch",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 1.8 },
    options: [],
    isDependent: true,
    required: true,
    dependencyLabel: "Please fill class and batch",
    fieldKeyLists: {},
    list: "sub_batch_list",
    keyValue: { label: "sub_batch_name", value: "sub_batch_id" },
  },
  {
    id: "college_list",
    type: "multi-select-dependency",
    label: "College",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.2 },
    isDependent: true,
    options: [],
    list: "college_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "college_name", value: "college_id" },
    fieldKeyLists: {},
  },
  {
    id: "platform_list",
    type: "multi-select-dependency",
    label: "platform",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 2.1 },
    // isDependent: true,
    options: [
      { label: "nlearn", value: "nlearn" },
      { label: "nkids", value: "nkids" },
    ],
    list: "platform_list",
    dependencyLabel: "Please select State",
    keyValue: { label: "label", value: "value" },
    fieldKeyLists: {},
  },
  {
    id: "boolean_operation",
    type: "normal-select",
    label: "Filter Operation",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 3, lg: 2.1 },
    options: [
      { label: "AND", value: "AND" },
      { label: "OR", value: "OR" },
    ],
  },
];
