import { Button, Grid, Paper } from "@mui/material";
import useReactHookForm from "hooks/useReactHookForm";
import DynamicForm from "shared/organisms/dynamicform";

import ContentRight from "shared/molecules/content-right";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import { StudentCourseLookupRequest } from "pages/configue/slices/student-course-slice";
import {
  createProgramInitialValues,
  createProgramProperties,
  createProgramValidationSchema,
} from "./helper";
import { StudentProgramLookupRequest } from "pages/configue/slices/student-program-slice";
import { getBatchListData } from "utils/metadata-split/metaDataApiFetch";
import { hasLengthGreaterThanZero } from "pages/configue/constants";

export const ProgramLookUpFilter = ({setSearchPayload,setOffset}) => {
  const { control, errors, getValues, watch, setValue } = useReactHookForm(
    createProgramInitialValues,
    createProgramValidationSchema,
    ""
  );
  const dispatch = useAppDispatch();
  const { isLoading: isLoad, data: metaData }: any = useAppSelector(
    (state) => state.metadataReducer
  );

  const convertArrayToString = (arr) => {
    const params = {};
    let payloadString = "";
    if (typeof arr == "string") {
      return arr;
    } else if (arr?.length > 0) {
      arr.forEach((sateid, i) => {
        if (i == arr.length - 1) {
          payloadString = payloadString + sateid;
        } else {
          payloadString = payloadString + sateid + ",";
        }
      });
      return payloadString;
    }
  };

  const clearFilter =()=>{
    setValue("class_list", []);
    setValue("match_id_list", []);
    setValue("match_type", "");
    setValue("program_id", "");
    setValue("boolean_operation", "");

    setSearchPayload({})
    setOffset(0)
  }

  const handleSearch = () => {
    const payload = {
      class: convertArrayToString(watch()?.class_list),
      match_id: convertArrayToString(watch()?.match_id_list),
      match_type: watch()?.match_type,
      program: watch()?.program_id,
      boolean_operation: watch()?.boolean_operation == "AND" ? "AND" : "OR",
    };
    
    setSearchPayload(payload)
    setOffset(0)
  };

  const updateMetaData = async (listData, metaKey) => {
    let metadataList = { ...metaData };
    metadataList[metaKey] = listData;
    await dispatch(metadataSuccess(metadataList));
  };

  useEffect(() => {
    setTimeout(async () => {
      if (watch()?.match_type == "batch") {
        const call1 = await getBatchListData("", {});
        if (call1) {
          updateMetaData(call1[0], call1[1]);
        } else {
          updateMetaData([], "batch_list");
        }
      }
      setValue("match_id_list", []);
    }, 1000);
  }, [watch()?.match_type]);

  return (
    <div>
      <Paper elevation={2} sx={{ px: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <DynamicForm
            fields={createProgramProperties(watch())}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
          <ContentRight sx={{ mt: 0 }}>
          <Button
              variant="contained"
              sx={{
                width: "90px",
                height: "40px",
                marginTop: "15px",
                marginRight:"10px",
                padding: "7px 20px 7px 20px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={clearFilter}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              clear 
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "80px",
                height: "40px",
                marginTop: "15px",
                padding: "7px 42.5px 7px 48.5px",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={handleSearch}
              disabled={hasLengthGreaterThanZero(watch(), "boolean_operation")}
            >
              search
            </Button>
          </ContentRight>
        </Grid>
      </Paper>
    </div>
  );
};
