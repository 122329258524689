import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  paddings: {
    "& .css-12qr4rk-MuiTableCell-root": {
      padding: 0,
      color: "red",
    },
  },
});

export default function TableTitle({ data }: any) {
  const styles = useStyles();
  return (
    <>
      {data.map((item: any) => (
        <TableCell key={item.title}>{item.title}</TableCell>
      ))}
      <TableCell ></TableCell>
    </>
  );
}
