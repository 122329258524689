import { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { saveAs } from "file-saver";
import { Controller } from "react-hook-form";
import { getItem } from "utils/helpers";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FieldError from "shared/organisms/dynamicform/FieldError";
import { getDataFromApi, convertToLabelValueFormat } from "./helpers";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { metadataSuccess } from "app-component/slices/metadata-slice";

interface ISingleSelectDependencyFieldProps {
  field: any;
  control: any;
  errors: any;
  setSelectedData: any;
  setValue: any;
  getValues?: any;
  watch?: any;
}

const SingleSelectWithDownload = ({
  field,
  control,
  errors,
  setValue,
  getValues,
  watch,
}: ISingleSelectDependencyFieldProps) => {
  const [options, setOptions] = useState([]);
  const dispatch=useAppDispatch();
  const { data: metaData, updatedAt } = useAppSelector(
    (state) => state.metadataReducer
  );

  const setOptionsUtility = () => {
    if (field.isDependent && metaData) {
      const keys: any = Object.keys(field.fieldKeyLists);
      const vals: any = Object.values(field.fieldKeyLists);
      let obj: any = metaData;
      for (let i = 0; i < keys.length; i++) {
        obj = searchAndGetObj(obj, watch[vals[i]], vals[i], keys[i]);
      }
      if (obj && obj[field.list] && obj[field.list].length > 0) {
        const temp = obj[field.list];
        let opts: any = [];
        if (temp && temp.length > 0) {
          opts = convertToLabelValueFormat(field, temp);
          setOptions(opts);
        }
      } else {
        setOptions([]);
      }
    } else {
      const jsonMetaData = metaData;
      const temp = jsonMetaData[field.list];
      let opts: any = [];
      if (temp && temp.length > 0) {
        opts = convertToLabelValueFormat(field, temp);
        setOptions(opts);
      }
    }
  };
  useEffect(() => {
    setOptionsUtility();
  }, [watch, updatedAt]);

  useEffect(() => {
    const updateMetaData=(listData)=>{
      let metadataList = {...metaData};
      metadataList[field.list] = listData;
      dispatch(metadataSuccess(metadataList))
    }

    if (field.isDependent && watch[field.changeValueId]) {
      const keys: any = Object.keys(field.fieldKeyLists);
      if (keys.length === 0 && field.hasApiCall) {
        getDataFromApi(field, watch, updateMetaData).then((data) => {
          setOptions(data);
        });
      }
    }
  }, [watch[field.changeValueId]]);

  const handleChange = (event: any, onChange: any) => {
    onChange(event.target.value);

    if (field.dependentFields)
      field.dependentFields.map((item: string) => setValue(`${item}`, ""));
  };

  const handleDownload = (id) => {
    let obj: any = options.find((item: any) => item.value === id);
    let arr = obj.url.split("/");
    const fileName = arr[arr.length - 1];
    saveAs(obj.url, fileName);
  };

  const hasOptions = options && options.length > 0;

  return (
    <Grid item xs={12} {...field.breakpoints}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl
                sx={{ width: "100%" }}
                error={errors && errors[field.id]?.message}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">
                  {field.label}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value || ""}
                  label={field.label}
                  inputProps={{
                    readOnly: field.readOnly ? field.readOnly : false,
                  }}
                  className={field.className}
                  fullWidth
                  required={field.required}
                  onChange={(e) => handleChange(e, onChange)}
                  MenuProps={{
                    sx: {
                      "&& .MuiList-root": {
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                      },
                    },
                  }}
                >
                  {field.isDependent && !hasOptions && (
                    <MenuItem disabled>
                      {field.dependencyLabel || "No options found"}
                    </MenuItem>
                  )}
                  {hasOptions && (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                  {hasOptions &&
                    options.map((option: any) => {
                      return (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Button onClick={() => handleDownload(value)} disabled={!value}>
                <CloudDownloadIcon />
              </Button>
            </div>
            {field.showError ? (
              <FieldError field={field} errors={errors} />
            ) : (
              field.customizableError
            )}
          </>
        )}
        name={field.id}
        control={control}
        defaultValue=""
      />
      {errors && errors[field.id]?.message && (
        <FieldError field={field} errors={errors} />
      )}
    </Grid>
  );
};

export default SingleSelectWithDownload;

const searchAndGetObj = (
  obj: any,
  value: string | number,
  key: string,
  listKey: string
) => {
  const arr = obj[listKey];
  let ob = {};
  if (!arr || arr.length === 0) return ob;
  arr.map((item: any) => {
    if (item[key] === value) {
      ob = item;
    }
  });
  return ob;
};
