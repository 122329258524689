import { getQueryParam } from "utils/helpers";
import { takeLatest, call, put, delay } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ResponseGenerator } from "interfaces";
import { CredsState } from "./interfaces";
import { loginRequest, loginSuccess, loginError } from "pages/login/slices";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { featureConfigRequest } from "app-component/slices/feature-config-slice";
import LoginService from "./service";
import { gmailLoginRequest, gmailLoginSuccess, gmailUserRoleRequest, gmailUserRoleSuccess } from "./gmail-slice";
import { metadataSuccess } from "app-component/slices/metadata-slice";
import history from "utils/history";


const loginService = new LoginService();

function* loginWorker(action: PayloadAction<CredsState>) {
  
  try {
    const res: ResponseGenerator = yield call(
      loginService.loginApi,
      action.payload
    );
    console.log("res", res);
    if (res.status === 200 && res.data.status === "success") {
      yield put(featureConfigRequest());
      yield localStorage.setItem("token", res.data.data.jwtToken);
      // const metadata = yield loginService.getMetaDataApi(
      //   "login",
      //   action.payload.username
      // );
      //yield put(metadataSuccess(metadata));
      //if (history.location.pathname === "/"){
      // await localStorage.setItem("metadata", JSON.stringify(res.data.data));
        //}
      yield localStorage.setItem("userName", action.payload.username);
      yield put(featureConfigRequest());
      yield put(loginSuccess(res.data.data));
      yield put(snackbarRequest({ message: "login successfully" }));
      setTimeout(()=>{
        history.replace("/assignments/create");
      },500)
    } else {
      yield put(
        snackbarRequest({
          type: "error",
          message: res.data.message || "Something went wrong!",
        })
      );
      yield put(loginError(res.data.message || "Something went wrong!"));
      yield put(
        snackbarRequest({
          message: res.data.message || "Something went wrong!",
          type: "error",
        })
      );
    }
  } catch (e) {
    yield put(loginError("Something went wrong!"));
  }
}

function* gmailLoginWorker(action: PayloadAction<CredsState>) {
  try {
    const token = getQueryParam("jwtToken", action.payload);
    let userName = getQueryParam("userName", action.payload);
    const refreshToken = getQueryParam("refreshToken", action.payload);

    userName = userName.replaceAll("%20", " ");
    yield localStorage.setItem("token", token);
    //yield localStorage.setItem("fetchFromLogin", "true");// will change for now need for initial api work in app.tsx
    // const metadata = yield loginService.getMetaDataApi("login", userName);
    // yield put(metadataSuccess(metadata));
    yield localStorage.setItem("userName", userName);
    yield localStorage.setItem("refreshToken", refreshToken);
    yield put(gmailLoginSuccess());
    yield put(featureConfigRequest());
    yield put(snackbarRequest({ message: "login successfully" }));
    const resUser = yield call(LoginService.getUserRole);
    if (resUser.status === 200 && resUser.data.status === "success") {
      yield put(gmailUserRoleSuccess(resUser?.data?.data));
      const role = resUser?.data?.data?.role.split(",");
      if (role.includes("content_admin")) {
        setTimeout(() => {
          history.replace("/assignments/create");
        }, 500);
      } else {
        setTimeout(() => {
          history.replace("configue/force_update");
        }, 500);
      }
    }
    // setTimeout(()=>{
    //   history.replace("/assignments/create");
    // },500)
  } catch (error) {
    yield put(loginError("Something went wrong!"));
  }
}

function* getUserRole() {
  try {
    const res = yield call(LoginService.getUserRole);
    if (res.status === 200 && res.data.status === "success") {
      yield put(gmailUserRoleSuccess(res.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* rootSaga() {
  yield takeLatest(loginRequest.type, loginWorker);
  yield takeLatest(gmailLoginRequest.type, gmailLoginWorker);
  yield takeLatest(gmailUserRoleRequest.type, getUserRole);
}
